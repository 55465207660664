<template>
    <IonPage>
        <IonHeader>
            <!-- <searchbar> -->
            <IonToolbar>
                <IonSearchbar class="searchbar" :class="searchbarModifiers" v-model="searchQuery"/>
            </IonToolbar>
            <!-- </searchbar> -->
            <!-- <search-results-filter> -->
            <IonToolbar v-if="searchResult">
                <div class="flex flex--100 flex--x-align-center filter-section">
                    <IonSegment :value="selectedFilter" v-model="selectedFilter">
                        <IonSegmentButton :value="FilterType.POLLS">
                            <IonLabel>Polls ({{ foundPolls.length ?? 0 }})</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton :value="FilterType.USERS">
                            <IonLabel>Users ({{ foundUsers.length ?? 0 }})</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton :value="FilterType.CATEGORIES">
                            <IonLabel>Categories ({{ foundCategories.length ?? 0 }})</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </div>
            </IonToolbar>
            <!-- </search-results-filter> -->
        </IonHeader>
        <IonContent>

            <div
                class="flex flex--100 flex--x-align-center flex--y-align-center loading-section"
                v-show="isWaitingSearchServerResponse"
            >
                <IonSpinner name="circles"/>
            </div>

            <div class="flex flex--100 results-section" v-if="searchResult && totalFoundResults > 0">
                <!-- <polls> -->
                <div class="items-section" v-show="pollsAreVisible">
                    <IonList>
                        <IonListHeader>
                            <IonLabel>Polls</IonLabel>
                        </IonListHeader>
                        <IonItem
                            v-for="{ id, } in foundPolls"
                            :key="id"
                            :button="true"
                            @click="toPollView(id)"
                        >
                            <SimplePollPreview :id="id"/>
                        </IonItem>
                    </IonList>
                </div>
                <!-- </polls> -->
                <!-- <users> -->
                <div class="items-section" v-show="usersAreVisible">
                    <IonList>
                        <IonListHeader>
                            <IonLabel>Users</IonLabel>
                        </IonListHeader>
                        <IonItem
                            v-for="user in foundUsers"
                            :key="user.id"
                            :button="true"
                            @click="toUserView(user.id)"
                        >
                            <UserPreview :user="user"/>
                        </IonItem>
                    </IonList>
                </div>
                <!-- </users> -->
                <!-- <categories> -->
                <div class="items-section" v-show="categoriesAreVisible">
                    <IonList>
                        <IonListHeader class="flex flex--100">
                            <IonLabel>Categories</IonLabel>
                        </IonListHeader>
                        <IonItem
                            v-for="{ id, } in foundCategories"
                            :key="id"
                            :button="true"
                            @click="toCategoryView(id)"
                        >
                            <SimpleCategory :id="id"/>
                        </IonItem>
                    </IonList>
                </div>
                <!-- </categories> -->
            </div>

            <div
                class="flex flex--100 flex--x-align-center flex--y-align-center no-results-section"
                v-show="!isWaitingSearchServerResponse && searchQuery && totalFoundResults === 0 && searchResult"
            >
                <h2 class="no-results-section__title">
                    No results found
                </h2>
            </div>

            <div
                class="flex flex--100 search-feed-section"
                v-if="!isWaitingSearchServerResponse && !searchResult"
            >
                <TrendingTopics @query-pressed="onQueryPressed"/>
                <SearchFeed/>
                <hr class="flex flex--100 safe-bottom-area"/>
            </div>

        </IonContent>
    </IonPage>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    IonSpinner, IonItem,
    IonList,
    IonListHeader,
    IonLabel,
} from "@ionic/vue";
import { debounce } from "lodash/function";
import { userManager } from "@/UserManager";
import SimplePoll from "@/components/polls/SimplePoll.vue";
import { categoriesStore, pollsStore } from "@/main";
import SimpleCategory from "@/components/categories/SimpleCategory.vue";
import SimplePollPreview from "@/components/polls/SimplePollPreview.vue";
import SearchFeed from "@/components/SearchFeed.vue";
import TrendingTopics from "@/components/TrendingTopics.vue";
import UserPreview from "@/components/users/UserPreview.vue";

const FilterType = Object.freeze({
    POLLS: 0,
    USERS: 1,
    CATEGORIES: 2,
});

const searchDebounceIntervalMs = 666;
const defaultFilter = FilterType.POLLS;

export default {
    name: "SearchView",
    components: {
        UserPreview,
        TrendingTopics,
        SearchFeed,
        SimplePollPreview,
        SimpleCategory,
        IonItem,
        SimplePoll,
        IonHeader, IonIcon, IonPage, IonToolbar, IonContent, IonButtons, IonButton, UserAvatar, IonTitle,
        IonSearchbar, IonSegment, IonSegmentButton, IonSpinner, IonList, IonListHeader, IonLabel,
    },
    data () {
        return {
            isWaitingSearchServerResponse: false,
            searchQuery: "",
            searchResult: undefined,
            selectedFilter: defaultFilter,
            debouncedSearch: debounce(this.fetch, searchDebounceIntervalMs),
            shouldNullifyDebounce: false,
        };
    },
    computed: {
        FilterType () {
            return FilterType;
        },

        foundPolls () {
            return this.searchResult?.polls ?? [];
        },

        foundCategories () {
            return this.searchResult?.categories ?? [];
        },

        foundUsers () {
            return this.searchResult?.users ?? [];
        },

        totalFoundResults () {
            return (
                this.foundPolls.length
                + this.foundCategories.length
                + this.foundUsers.length
            );
        },

        pollsAreVisible () {
            return (
                this.foundPolls.length > 0
                && (
                    this.selectedFilter === FilterType.ALL
                    || this.selectedFilter === FilterType.POLLS
                )
            );
        },

        usersAreVisible () {
            return (
                this.foundUsers.length > 0
                && (
                    this.selectedFilter === FilterType.ALL
                    || this.selectedFilter === FilterType.USERS
                )
            );
        },

        categoriesAreVisible () {
            return (
                this.foundCategories.length > 0
                && (
                    this.selectedFilter === FilterType.ALL
                    || this.selectedFilter === FilterType.CATEGORIES
                )
            );
        },

        searchbarModifiers () {
            return {
                "searchbar--filter-active": this.searchResult,
            };
        },
    },
    methods: {
        toPollView (pollId) {
            this.$router.push(`/navigation/polls/${pollId}`);
        },

        toUserView (userId) {
            this.$router.push(`/navigation/users/${userId}`);
        },

        toCategoryView (categoryId) {
            this.$router.push(`/navigation/categories/${categoryId}`);
        },

        async fetch () {
            if (this.isWaitingSearchServerResponse) {
                return;
            }

            this.isWaitingSearchServerResponse = true;
            this.searchResult = undefined;

            if (this.searchQuery) {
                const result = await userManager.search(this.searchQuery);
                const polls = result.polls;
                const categories = result.categories ?? [];

                pollsStore.updateMany(polls);

                this.searchResult = result;
            }
            else {
                this.searchResult = undefined;
            }

            this.isWaitingSearchServerResponse = false;
        },

        onQueryPressed (query) {
            this.shouldNullifyDebounce = true;
            this.searchQuery = query;
        },
    },
    watch: {
        searchQuery (value) {
            this.selectedFilter = defaultFilter;

            if (!value) {
                this.searchResult = undefined;

                return;
            }

            if (this.shouldNullifyDebounce) {
                this.shouldNullifyDebounce = false;

                this.fetch();
            }
            else {
                this.debouncedSearch();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.searchbar {
    padding-top: 21px;
    padding-bottom: 21px;

    &--filter-active {
        padding-bottom: 0;
    }
}

.results-section {
    padding: 0 0 13px 0;
}

.items-section {
    width: 100%;
}

.loading-section {
    height: 100%;
}

.filter-section {
    margin: 0;
    padding: 13px 0;

    background-color: rgb(255, 255, 255);

    z-index: 1;
}

.safe-bottom-area {
    margin: 0;
    padding: 0;

    height: 11px;
}

.no-results-section {
    cursor: default;
    user-select: none;
    overflow: hidden;

    height: 100%;

    &__title {
        margin: 21px;

        font-size: 23px;
        font-weight: 600;
        letter-spacing: 0.01rem;
        color: rgb(0, 0, 0);
        text-align: center;
    }
}
</style>
