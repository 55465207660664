<template>
    <IonPage v-if="user">
    <IonHeader>
        <IonToolbar class="toolbar">
            <IonButtons slot="start">
                <IonBackButton text=""/>
            </IonButtons>
            <IonButtons slot="end">
                <IonButton @click="toSettingsView" v-if="isOwner">
                    Settings
                    <IonIcon :icon="rightArrowIcon"/>
                </IonButton>
                <FollowButton
                    :value="isFollowing"
                    @click="toggleFollow"
                    v-if="!isOwner && !headerIsVisible"
                />
            </IonButtons>
        </IonToolbar>
    </IonHeader>
    <IonContent class="ion-content">
        <div class="flex flex--100 main">
            <!-- <unclaimed> -->
            <UnclaimedProfileDisclaimer v-if="isUnclaimed"/>
            <!-- </unclaimed> -->
            <div class="flex flex--100 flex--x-align-center header" ref="header">
                <div class="flex flex--100 flex--y-align-center">
                    <div class="flex">
                        <UserAvatar :user="user" @click="onUserAvatarPressed"/>
                    </div>
                    <div class="flex primary-section">
                        <div class="flex flex--100 flex--x-align-between user-statistics-section">
                            <div class="flex flex--x-align-center counter">
                                <span
                                    class="flex flex--100 flex--x-align-center counter__value"
                                >
                                    {{ visiblePollsCounter }}
                                </span>
                                polls
                            </div>
                            <div class="flex flex--x-align-center counter">
                                <span
                                    class="flex flex--100 flex--x-align-center counter__value"
                                >
                                    {{ visibleFollowersCounter }}
                                </span>
                                followers
                            </div>
                            <div class="flex flex--x-align-center counter">
                                <span
                                    class="flex flex--100 flex--x-align-center counter__value"
                                >
                                    {{ visibleFollowingCounter }}
                                </span>
                                following
                            </div>
                        </div>
                    </div>
                    <div class="flex flex--100 flex--y-align-center name-section">
                        <span class="name">{{ visibleName }}</span>
                        <VerifiedBadgeIcon class="badge-icon" v-if="user.isCreator"/>
                    </div>
                    <div class="flex flex--100" v-if="biography">
                        <p class="biography">
                            <template
                                v-for="(row, i) in biographyRows"
                                :key="i"
                            >
                                {{ row }}
                                <br v-if="i + 1 < biographyRows.length">
                            </template>
                        </p>
                    </div>
                    <div class="flex flex--100">
                        <FollowButton
                            :value="isFollowing"
                            @click="toggleFollow"
                            v-if="!isOwner"
                        />
                        <SimpleButton
                            text="Edit profile"
                            class="modify-button"
                            @click="toMyIdentityView"
                            v-if="isOwner"
                        />
                        <SimpleButton
                            text="Share profile"
                            class="share-button"
                            @click="showShareProfileOverlay"
                        />
                    </div>
                </div>
            </div>
            <div class="flex flex--100 activity-selector">
                <IonSegment :value="selectedActivity" v-model="selectedActivity" class="activity-segment">
                    <IonSegmentButton :value="ActivityType.POLLS" class="activity-segment-button">
                        <GridIcon class="activity-segment-icon"/>
                    </IonSegmentButton>
                </IonSegment>
            </div>
            <!-- <polls -->
            <div
                class="flex flex--100 polls-section"
                v-show="pollsAreVisible && polls.length > 0"
            >
                <SimplePollSquarePreview
                    v-for="{ id, } in polls"
                    :key="id"
                    :id="id"
                    @click="toPollView(id)"
                ></SimplePollSquarePreview>
                <hr class="flex flex--100 safe-bottom-area"/>
            </div>
            <div
                class="flex flex--100 flex--y-align-center no-activity-section"
                v-show="pollsAreVisible && polls.length === 0"
            >
                <div class="flex flex--100 flex--x-align-center">
                    <h2 class="title">No polls yet</h2>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <span class="subtitle">You can become a creator</span>
                </div>
            </div>
            <!-- </polls> -->
            <!-- <comments> -->
            <div
                class="flex flex--100 comments-section"
                v-show="commentsAreVisible && comments.length > 0"
            >
            </div>
            <div
                class="flex flex--100 flex--y-align-center no-activity-section"
                v-show="commentsAreVisible && comments.length === 0"
            >
                <div class="flex flex--100 flex--x-align-center">
                    <h2 class="title">No comments yet</h2>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <span class="subtitle">Start a conversation</span>
                </div>
            </div>
            <!-- </comments> -->
            <!-- <votes> -->
            <div
                class="flex flex--100 votes-section"
                v-show="votesAreVisible && votes.length > 0"
            >
            </div>
            <div
                class="flex flex--100 flex--y-align-center no-activity-section"
                v-show="votesAreVisible && votes.length === 0"
            >
                <div class="flex flex--100 flex--x-align-center">
                    <h2 class="title">No votes yet</h2>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <span class="subtitle">Try exploring the feed</span>
                </div>
            </div>
            <!-- </votes> -->
        </div>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon, IonBackButton, IonSegment, IonSegmentButton, IonLabel,
} from "@ionic/vue";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/UserStore";

import { chevronForward, } from "ionicons/icons";
import UserAvatar from "@/components/UserAvatar.vue";
import FollowButton from "@/components/buttons/FollowButton.vue";
import {userManager} from "@/UserManager";
import { ActivityType, numberToCompactString } from "@/utilities/Utilities";
import VerifiedBadgeIcon from "@/components/icons/VerifiedBadgeIcon.vue";
import SimpleButton from "@/components/buttons/SimpleButton.vue";
import { usePollsStore } from "@/stores/PollsStore";
import SimplePollSquarePreview from "@/components/polls/SimplePollSquarePreview.vue";
import GridIcon from "@/components/icons/GridIcon.vue";
import OpenBookIcon from "@/components/icons/OpenBookIcon.vue";
import UserActivityIcon from "@/components/icons/UserActivityIcon.vue";
import { useUtilityStore, } from "@/stores/UtilityStore";
import UnclaimedProfileDisclaimer from "@/components/mixed/UnclaimedProfileDisclaimer.vue";
import { Camera, CameraResultType } from "@capacitor/camera";

export default {
    name: "UserView",
    components: {
        UnclaimedProfileDisclaimer,
        UserActivityIcon,
        OpenBookIcon,
        GridIcon,
        SimplePollSquarePreview,
        SimpleButton,
        IonBackButton,
        VerifiedBadgeIcon,
        FollowButton,
        UserAvatar,
        IonPage,
        IonHeader,
        IonToolbar,
        IonContent,
        IonButton,
        IonButtons,
        IonIcon,
        IonSegment,
        IonSegmentButton,
        IonLabel,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            isWaitingUserServerResponse: false,
            headerIsVisible: true,
            user: undefined,
            polls: [],
            comments: [],
            votes: [],
            selectedActivity: ActivityType.POLLS,
        };
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapStores(usePollsStore),

        loggedUser () {
            return this.userStore.user;
        },

        isOwner () {
            return this.id === this.loggedUser?.id;
        },

        isFollowing () {
            return this.user?.$user?.isFollowing === true;
        },

        visibleName () {
            return this.user.visibleName;
        },

        biography () {
            return this.user.biography;
        },

        biographyRows () {
            const biography = this.biography;

            if (!biography) {
                return [];
            }

            return biography.split("\n");
        },

        isUnclaimed () {
            return this.user.isCreator && this.user.creator.isUnclaimed;
        },

        rightArrowIcon () {
            return chevronForward;
        },

        visibleFollowersCounter () {
            return numberToCompactString(this.user.metrics.followersCounter);
        },

        visibleFollowingCounter () {
            return numberToCompactString(this.user.metrics.followedUsersCounter);
        },

        visiblePollsCounter () {
            return numberToCompactString(this.user.metrics.pollsCounter);
        },

        visibleVotesCounter () {
            return numberToCompactString(this.user.metrics.votesCounter);
        },

        pollsAreVisible () {
            return this.selectedActivity === ActivityType.POLLS;
        },

        commentsAreVisible () {
            return this.selectedActivity === ActivityType.COMMENTS;
        },

        votesAreVisible () {
            return this.selectedActivity === ActivityType.VOTES;
        },

        ActivityType () {
            return ActivityType;
        },

        ...mapStores(useUtilityStore),

        sharedComponents () {
            return this.utilityStore.sharedComponents;
        },
    },
    methods: {
        toSettingsView () {
            this.$router.push("/settings");
        },

        toMyIdentityView () {
            this.$router.push("/user/identity");
        },

        showShareProfileOverlay () {
            this.sharedComponents.shareProfileOverlay.show(this.user);
        },

        async toggleFollow () {
            if (!this.id) {
                return;
            }

            const {
                targetUser,
                loggedUser,
            } = await userManager.toggleUserFollow(this.id);

            this.user = targetUser;

            this.userStore.update(loggedUser);
        },

        async fetch () {
            if (this.isWaitingUserServerResponse) {
                return;
            }

            this.isWaitingUserServerResponse = true;

            if (this.id === this.loggedUser?.id) {
                this.user = this.loggedUser;
            }

            const {
                user: requestedUser,
            } = await userManager.getUser(this.id);
            this.isWaitingUserServerResponse = false;

            if (this.id === this.loggedUser?.id) {
                this.userStore.update(requestedUser);
            }

            this.user = requestedUser;

            await this.fetchCreatedPolls();
        },

        async fetchCreatedPolls () {
            const {
                createdPolls,
            } = await userManager.getCreatedPolls(this.id);

            this.pollsStore.updateMany(createdPolls);

            this.polls = createdPolls;
        },

        toPollView (pollId) {
            this.$router.push(`/navigation/polls/${pollId}`);
        },

        async pickImage () {
            const image = await Camera.getPhoto({
                webUseInput: true,
                resultType: CameraResultType.Uri,
            });
            const response = await fetch(image.webPath);

            return response.blob();
        },

        async updateProfileImage () {
            const image = await this.pickImage();
            const response = await userManager.user.updatePrimaryImage(image);
            const { user, } = response.data.result;

            this.userStore.update(user);
        },

        async onUserAvatarPressed () {
            if (this.isOwner) {
                await this.updateProfileImage();
            }
        },
    },
    created () {
        this.fetch();
    },
    mounted () {/*
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                this.headerIsVisible = entry.isIntersecting;
            });
        }, {
            threshold: 0,
        });

        observer.observe(this.$refs.header);*/
    },
    watch: {
        loggedUser () {
            if (this.isOwner) {
                this.user = this.loggedUser;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/mixins.scss";

@include activity-segment;

.ion-content {
    &::part(background) {
        background-color: rgb(250, 250, 250);
    }
}

.main {
    flex-direction: column;

    min-height: 100%;
}

.header {
    margin: 0;
    padding: 23px;

    background-color: rgb(250, 250, 250);
    //background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgba($brand-color, 0.1) 100%);
    border-bottom: $base-border;
}

.name {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.002rem;
    color: rgb(33, 33, 33);
}

.biography {
    margin: 3px 0 0 0;
    padding: 0;

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.002rem;
    color: rgb(37, 37, 37);
}

.primary-section {
    flex: 1;

    padding-left: 7px;
}

.toolbar {
    --background: rgb(250, 250, 250);
}

.user-avatar {
    width: 71px;
    height: 71px;
}

.follow-button,
.modify-button,
.share-button {
    margin-top: 13px;
}

.share-button {
    margin-left: 7px;
}

.user-statistics-section {

}

.counter {
    flex: 1;

    margin: 0;
    padding: 0;

    font-weight: 400;
    color: $base-text-color;
    font-size: 14px;

    &__value {
        font-weight: 700;
    }
}

.safe-bottom-area {
    margin: 0;
    padding: 0;

    height: 11px;
}

.badge-icon {
    width: 18px;
    margin-left: 3px;

    fill: $brand-color;
}

.comments-section {

}

.name-section {
    margin-top: 7px;
}

.activity-selector {
    position: sticky;
    top: 0;
}

.polls-section {
    flex-grow: 1;
}

.comments-section,
.votes-section {
    flex-grow: 1;
}

.no-activity-section {
    flex-grow: 1;
}

.title {
    margin: 0;
    padding: 0;

    font-size: 23px;
    font-weight: 600;
    letter-spacing: 0.01rem;
    color: rgb(0, 0, 0);
    text-align: center;
}

.subtitle {
    margin: 1px 0 0 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.01rem;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
}
</style>
