<template>
    <IonPage>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
            <IonTitle>Settings</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <IonList>
            <IonListHeader>
                <IonLabel>Account</IonLabel>
            </IonListHeader>
            <IonItem :button="true" @click="toMyIdentityView">
                <IonLabel>My Identity</IonLabel>
            </IonItem>
            <IonItem :button="true" @click="toPreferencesView">
                <IonLabel>My Preferences</IonLabel>
            </IonItem>
            <IonItem :button="true" @click="toMyCategoriesView">
                <IonLabel>My Categories</IonLabel>
            </IonItem>
            <IonItem :button="true" @click="toBillingView">
                <IonLabel>Subscriptions & Billing</IonLabel>
            </IonItem>
            <IonItem :button="true" @click="toPrivacySecurityView">
                <IonLabel>Privacy & Security</IonLabel>
            </IonItem>
            <IonItem :button="true" @click="toCreatorSignUpView" v-if="!isCreator">
                <IonLabel color="primary">Switch to Creator</IonLabel>
            </IonItem>
        </IonList>
        <IonList>
            <IonListHeader>
                <IonLabel>Activity</IonLabel>
            </IonListHeader>
            <IonItem :button="true" @click="toMyPollsView" v-if="isCreator">
                <IonLabel>My Polls</IonLabel>
            </IonItem>
            <IonItem :button="true" @click="toMyVotesView">
                <IonLabel>My Votes</IonLabel>
            </IonItem>
            <!--
            <IonItem :button="true" :disabled="true">
                <IonLabel>My Comments</IonLabel>
            </IonItem>
            -->
        </IonList>
        <IonList>
            <IonListHeader>
                <IonLabel>
                    Security
                </IonLabel>
            </IonListHeader>
            <!--
            <IonItem :button="true" :disabled="true">
                <IonLabel>Change Password</IonLabel>
            </IonItem>
            <IonItem :button="true" :disabled="true">
                <IonLabel>Change Mobile Number</IonLabel>
            </IonItem>
            -->
            <IonItem :button="true" @click="signOut">
                <IonLabel color="danger">Sign Out</IonLabel>
            </IonItem>
        </IonList>
        <div class="flex flex--100 flex--x-align-center">
            <span class="footer-text">Vottify</span>
        </div>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { userManager, } from "@/UserManager";
import { mapStores, } from "pinia";
import { useUserStore, } from "@/stores/UserStore";

export default {
    name: "SettingsView",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
    },
    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        isCreator () {
            return this.loggedUser.isCreator;
        },
    },
    methods: {
        signOut () {
            userManager.signOut();
            this.$router.push("/sign-in");
        },

        toMyIdentityView () {
            this.$router.push("/user/identity");
        },

        toMyVotesView () {
            this.$router.push("/user/votes");
        },

        toMyPollsView () {
            this.$router.push("/user/polls");
        },

        toCreatorSignUpView () {
            this.$router.push("/sign-up/creator");
        },

        toPreferencesView () {
            this.$router.push("/settings/preferences");
        },

        toMyCategoriesView () {
            this.$router.push("/user/categories");
        },

        toPrivacySecurityView () {
            this.$router.push("/privacy-and-security");
        },

        toBillingView () {
            this.$router.push("/user/billing");
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-text {
    margin: 31px 0;
    padding: 0;

    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.02rem;
    color: rgb(150, 150, 150);
}
</style>
