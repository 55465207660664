<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <!-- <back-button> -->
                <IonButtons slot="start">
                    <IonBackButton v-if="currentStep === 1"/>
                    <IonBackButton default-href="#" @click="onBackButtonClick" v-else/>
                </IonButtons>
                <!-- </back-button> -->
                <IonTitle>New Poll</IonTitle>
                <!-- <next-button> -->
                <IonButtons slot="end">
                    <!--
                    <IonButton>
                        Preview
                    </IonButton>
                    -->
                    <IonButton :disabled="!canNext" @click="nextStep">
                        Next
                        <IonIcon :icon="rightArrowIcon"/>
                    </IonButton>
                </IonButtons>
                <!-- </next-button> -->
            </IonToolbar>
            <IonToolbar>
                <!-- <progress-bar> -->
                <div class="flex flex--100">
                    <SimpleProgressBar :progress="currentStep / totalSteps"/>
                </div>
                <!-- </progress-bar> -->
            </IonToolbar>
        </IonHeader>
        <IonContent class="ion-content">
            <div class="flex flex--100 poll-form">
                <div class="flex flex--100" v-show="currentStep === 1">
                    <div class="flex flex--100 question-and-description-section">
                        <!-- <question> -->
                        <IonTextarea
                            class="question-input"
                            placeholder="What do you want to talk about?"
                            :counter="true"
                            :auto-grow="true"
                            v-model="poll.question"
                            :value="poll.question"
                            :rows="2"
                            maxlength="180"
                            ref="questionInput"
                        ></IonTextarea>
                        <!-- </question> -->

                        <!-- <description> -->
                        <IonTextarea
                            class="description-input"
                            placeholder="Description (optional)"
                            :counter="true"
                            :auto-grow="true"
                            v-model="poll.description"
                            :value="poll.description"
                            :rows="1"
                            maxlength="360"
                        />
                        <!-- </description> -->
                    </div>

                    <div class="flex flex--100 simple-section options-section">
                        <div
                            class="flex flex--100 option"
                            v-for="(option, i) of options"
                            :key="i"
                        >
                            <IonInput
                                type="text"
                                fill="outline"
                                :label="'Option ' + (i + 1)"
                                label-placement="floating"
                                :counter="true"
                                maxlength="110"
                                v-model="poll.options[i]"
                                :value="option"
                            />
                            <span
                                v-show="(i + 1) > 2"
                                @click="removeOptionByIndex(i)"
                                class="remove-option-button"
                            >
                                <IonButton size="small" color="danger">
                                    <IonIcon :icon="removeOutline"/>
                                </IonButton>
                            </span>
                        </div>

                        <div class="flex flex--100">
                            <span
                                class="add-option-button"
                                @click="addOption"
                                v-show="canAddOption"
                            >
                                <IonButton size="small">
                                    Add Option
                                    <IonIcon :icon="addOutline"/>
                                </IonButton>
                            </span>
                        </div>
                    </div>

                </div>

                <div class="flex flex--100" v-show="currentStep === 2">

                    <div class="flex flex--100 simple-section">

                        <h2 class="title">Categories</h2>

                        <SimpleCategorySelector
                            :categories="categories"
                            v-model:value="poll.categories"
                        />

                    </div>

                    <div class="flex flex--100 simple-section">

                        <h2 class="title">Countries</h2>

                        <SimpleCountrySelector
                            :countries="countries"
                            :items-per-row="countries.length / 2"
                            v-model:value="poll.countries"
                        />

                    </div>

                </div>

                <div class="flex flex--100" v-show="currentStep === 3">

                    <div class="flex flex--100 simple-section">

                        <div class="flex flex--100">
                            <h2 class="title">Poll Duration</h2>
                        </div>

                        <IonItem class="flex flex--100">
                            <IonSelect
                                interface="action-sheet"
                                v-model="poll.duration"
                                cancel-text="Dismiss"
                                label-placement="floating"
                                label="Duration"
                            >
                                <IonSelectOption value="H1">1 hour</IonSelectOption>
                                <IonSelectOption value="H2">2 hours</IonSelectOption>
                                <IonSelectOption value="H4">4 hours</IonSelectOption>
                                <IonSelectOption value="H8">8 hours</IonSelectOption>
                                <IonSelectOption value="D1">1 day</IonSelectOption>
                                <IonSelectOption value="D2">2 days</IonSelectOption>
                                <IonSelectOption value="D3">3 days</IonSelectOption>
                                <IonSelectOption value="D4">4 days</IonSelectOption>
                                <IonSelectOption value="D5">5 days</IonSelectOption>
                                <IonSelectOption value="D6">6 days</IonSelectOption>
                                <IonSelectOption value="W1">1 week</IonSelectOption>
                            </IonSelect>
                        </IonItem>

                    </div>

                    <div class="flex flex--100 simple-section">

                        <div class="flex flex--100">
                            <h2 class="title">Poll Action</h2>
                        </div>

                        <IonItem class="flex flex--100">
                            <IonSelect
                                interface="action-sheet"
                                v-model="actionType"
                                cancel-text="Dismiss"
                                label="Action"
                                label-placement="floating"
                            >
                                <IonSelectOption :value="'none'">None</IonSelectOption>
                                <IonSelectOption :value="0">Shop Now</IonSelectOption>
                                <IonSelectOption :value="1">Donate Now</IonSelectOption>
                                <IonSelectOption :value="2">Sign Petition</IonSelectOption>
                            </IonSelect>
                        </IonItem>

                        <!-- <action-uri> -->
                        <IonItem class="flex flex--100" v-if="actionType !== 'none'">
                            <IonInput
                                class="action-uri-input"
                                ref="actionUriInput"
                                type="text"
                                label="Action URL"
                                label-placement="floating"
                                v-model="poll.actionUri"
                                :value="poll.actionUri"
                            ></IonInput>
                        </IonItem>
                        <!-- </action-uri> -->

                    </div>

                    <div class="flex flex--100 flex--x-align-around simple-section">

                        <div class="flex flex--100">
                            <h2 class="title">Publication Time</h2>
                        </div>

                        <IonItem class="flex flex--100">
                            <IonToggle class="flex flex--100" v-model:model-value="mustPublishNow" @ionChange="togglePublishNow">
                                Publish Now
                            </IonToggle>
                        </IonItem>

                        <div class="flex flex--100 flex--x-align-center">

                            <div class="flex ion-margin" v-show="!mustPublishNow">
                                <IonDatetimeButton datetime="publishAt"/>
                            </div>

                        </div>

                    </div>
                    <div class="flex flex--100 flex--x-align-around simple-section">

                        <div class="flex flex--100">
                            <h2 class="title">Moderation</h2>
                        </div>

                        <IonItem class="flex flex--100">
                            <IonToggle
                                class="flex flex--100"
                                v-model:model-value="poll.isPrivate"
                            >
                                Private
                            </IonToggle>
                        </IonItem>

                    </div>
                    <div class="flex flex--100 flex--x-align-around simple-section">

                        <div class="flex flex--100">
                            <h2 class="title">Social Media</h2>
                        </div>

                        <IonItem class="flex flex--100">
                            <IonToggle
                                class="flex flex--100"
                                :model-value="integrations.publishOnTwitter"
                                @click="togglePublishOnTwitter"
                            >
                                Publish on X (Twitter)
                            </IonToggle>
                        </IonItem>

                    </div>

                </div>

            </div>
            <hr class="safe-bottom-area"/>
        </IonContent>
        <IonModal class="datetime-modal" :keep-contents-mounted="true">
            <IonDatetime
                :min="minPublishAt"
                :max="maxPublishAt"
                minute-values="0,15,30"
                id="publishAt"
                v-model="poll.publishAt"
                presentation="date-time"
            />
        </IonModal>
    </IonPage>
</template>

<script>
import {
    IonAccordion,
    IonAccordionGroup,
    IonBackButton,
    IonButton,
    IonButtons,
    IonChip,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar,
} from "@ionic/vue";
import SimplePoll from "@/components/polls/SimplePoll.vue";
import SimpleCategorySelector from "@/components/categories/SimpleCategorySelector.vue";
import { userManager } from "@/UserManager";
import { addOutline, chevronForward, closeCircle, closeOutline, } from "ionicons/icons";
import { countries, } from "@/utilities/Countries";
import SimpleCountrySelector from "@/components/countries/SimpleCountrySelector.vue";
import SimpleProgressBar from "@/components/mixed/SimpleProgressBar.vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import { connectTwitterAccount, } from "@/UserIntegrations";

function minPublishAt () {
    let currentDate = new Date();

    currentDate.setMinutes(0, 0, 0);

    while (currentDate.getTime() < Date.now()) {
        currentDate = new Date(currentDate.getTime() + 60000 * 15);
    }

    return currentDate.toISOString();
}

export default {
    name: "NewPollView",
    components: {
        SimpleProgressBar,
        SimpleCountrySelector,
        IonIcon,
        SimpleCategorySelector,
        SimplePoll,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonChip,
        IonButton,
        IonAccordion,
        IonAccordionGroup,
        IonTextarea,
        IonInput,
        IonItemGroup,
        IonDatetime,
        IonDatetimeButton,
        IonModal,
        IonSelect,
        IonSelectOption,
        IonToggle,
    },
    data () {
        return {
            isWaitingServerResponse: false,
            currentStep: 1,
            totalSteps: 3,
            categories: [],
            actionType: "none",
            poll: {
                question: "",
                description: "",
                categories: [],
                countries: [],
                duration: "D1",
                publishAt: undefined,
                isPrivate: false,
                actionType: undefined,
                actionUri: undefined,
                options: [
                    "",
                    "",
                ],
            },
            mustPublishNow: true,
            integrations: {
                publishOnTwitter: false,
            },
        };
    },
    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        minPublishAt () {
            return minPublishAt();
        },

        maxPublishAt () {
            return new Date(Date.now() + 2592000000).toISOString();
        },

        selectedCategories () {
            return this.poll.categories
                .map((categoryId) => this.categories.find(({ id, }) => id === categoryId));
        },

        closeCircleIcon () {
            return closeCircle;
        },

        normalizedQuestion () {
            const question = this.poll.question;

            if (!question) {
                return "";
            }

            return `${question}?`;
        },

        options () {
            return this.poll.options;
        },

        maxOptions () {
            return 4;
        },

        canAddOption () {
            return this.options.length < this.maxOptions;
        },

        rightArrowIcon () {
            return chevronForward;
        },

        countries () {
            return countries.filter((country) => country.iso2 !== "ZZ");
        },

        addOutline () {
            return addOutline;
        },

        removeOutline () {
            return closeOutline;
        },

        hasConnectedTwitter () {
            return !!this.loggedUser.integrations?.hasConnectedTwitter;
        },

        canNext () {
            if (this.isWaitingServerResponse) {
                return false;
            }

            switch (this.currentStep) {
                case 1: {
                    return (
                        this.poll.question
                        && this.poll.options[0]
                        && this.poll.options[1]
                    );
                }
                case 2: {
                    return true;
                }
                case 3: {
                    return true;
                }
            }

            return false;
        },
    },
    methods: {
        togglePublishNow (event) {
            if (event.target.checked) {
                this.poll.publishAt = undefined;
            }
            else {
                this.poll.publishAt = minPublishAt();
            }
        },

        onBackButtonClick (event) {
            event.stopImmediatePropagation();

            if (this.currentStep > 1) {
                --this.currentStep;
            }
            else {
                this.$router.back();
            }
        },

        addOption () {
            this.poll.options.push("");
        },

        removeOptionByIndex (index) {
            const optionsCopy = JSON.parse(JSON.stringify(this.poll.options));

            optionsCopy.splice(index, 1);

            this.poll.options = optionsCopy;
        },

        onBaseInput (event) {
            const value = event.target.value;
            const filteredValue = value
                .replace(/[^a-zA-Z0-9\s?]+/g, '')
                .replace(/\s\s+/g, ' ');

            this.poll.question = filteredValue;
            this.$refs.questionInput.$el.value = filteredValue ;
        },

        async nextStep () {
            if (this.currentStep === this.totalSteps) {
                await this.publish();
            }
            else {
                ++this.currentStep;
            }
        },

        async publish () {
            this.isWaitingServerResponse = true;

            try {
                const poll = await userManager.createPoll(this.poll);

                this.$router.push("/navigation/feed");
            }
            catch (e) {
                console.log(e);
            }

            this.isWaitingServerResponse = false;
        },

        async fetch () {
            await this.fetchCategories();
        },

        async fetchCategories () {
            const response = await userManager.categories.getAll();
            const { categories, } = response.data.result;

            this.categories = categories;
        },

        async togglePublishOnTwitter (e) {
            e.stopImmediatePropagation();

            if (!this.hasConnectedTwitter) {
                await connectTwitterAccount();
            }
            else {
                this.integrations.publishOnTwitter =
                    !this.integrations.publishOnTwitter;
            }
        },
    },
    watch: {
        actionType (value) {
            if (value === "none") {
                this.poll.actionType = undefined;
            }
            else {
                this.poll.actionType = value;
            }
        },
    },
    created () {
        this.fetch();
    },
    ionViewDidLeave () {
        this.$resetState();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.poll-form {
    //width: calc(100% - 20px);
}

.question-and-description-section {
    //border-bottom: 0.5px solid rgb(200, 200, 200);
}

.ion-content {
    &::part(background) {
        background-image: linear-gradient(to top, rgb(255, 255, 255) 0%, rgba($brand-color, 0.1) 100%);
    }
}

.simple-section {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(230, 230, 230);
    border-radius: 12px;

    margin: 11px;
    padding: 11px 0;

    & + & {
        margin-top: 0;
    }
}

.options-section {
    padding: 26px 20px;
}

.option {
    position: relative;

    //border-top: 1px solid rgb(240, 240, 240);
}
.add-option-button {
    cursor: pointer;
}
.remove-option-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    z-index: 2;
}

.question-input {
    --border-width: 0;

    margin: 51px 0;
    padding: 0 20px;

    &::v-deep(.textarea-wrapper) {
        overflow-y: scroll;
        max-height: 151px;
    }
    &::v-deep(.textarea-wrapper::-webkit-scrollbar) {
        display: none;
    }

    &::v-deep(.native-textarea) {
        font-size: 21px;
        font-weight: 700;
        letter-spacing: 0.02rem;
        color: rgb(0, 0, 0);
    }

    &::v-deep(::placeholder) {
        font-size: 21px;
        font-weight: 600;
        letter-spacing: 0.01rem;
        color: rgb(0, 0, 0);
    }
}

.description-input {
    --border-width: 0;

    margin: 0 0 20px 0;
    padding: 0 20px;

    &::v-deep(.textarea-wrapper) {
        overflow-y: scroll;
        max-height: 151px;
    }
    &::v-deep(.textarea-wrapper::-webkit-scrollbar) {
        display: none;
    }

    &::v-deep(.native-textarea) {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(0, 0, 0);
    }

    &::v-deep(::placeholder) {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.01rem;
        color: rgb(0, 0, 0);
    }
}

.action-uri-input {

}

.title {
    margin: 10px 20px 20px 20px;

    font-size: 16px;
    font-weight: 600;
    color: $base-text-color;
}

.simple-category-selector {
    &::v-deep(.searchbar) {
        padding-top: 0;
    }
}

.simple-country-selector {
    &::v-deep(.searchbar) {
        padding-top: 0;
    }
}

.datetime-modal {
    &::part(content) {
        background-color: transparent;
    }
}

::v-deep(.simple-progress-bar) {
    margin: 3px;
    padding: 0;

    .application-logo {
        display: none;
    }
}
</style>
