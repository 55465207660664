<template>
    <div class="flex flex--100 flex--x-align-center mobile-number-input">
        <IonItemGroup class="flex flex--100">
            <IonItem class="flex flex--30 dial-code-input" @click="openDialCodePickerModal">
                <IonInput
                    label="Country Code"
                    label-placement="floating"
                    :disabled="true"
                    :value="visibleDialCode"
                ></IonInput>
            </IonItem>
            <IonItem class="flex flex--70 number-input">
                <IonInput
                    label="Mobile Number"
                    label-placement="floating"
                    autocomplete="tel-local"
                    v-model="localMobileNumber"
                ></IonInput>
            </IonItem>
        </IonItemGroup>
        <IonModal
            class="dial-code-picker-modal"
            :initial-breakpoint="0.6"
            :breakpoints="[0, 0.6, 0.8]"
            :is-open="dialCodePickerModalIsOpen"
            :keep-contents-mounted="true"
            @ionModalDidDismiss="dismissDialCodePickerModal"
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons>
                        <IonButton @click="dismissDialCodePickerModal">Dismiss</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSearchbar
                    ref="dialCodeSearchBar"
                    v-model="searchQuery"
                    placeholder="Search"
                    class="searchbar"
                ></IonSearchbar>
                <IonPicker :key="searchQuery" v-show="visibleCountries.length > 0">
                    <IonPickerColumn :value="localSelectedCountryIso" @ionChange="onCountryIsoChanged">
                        <IonPickerColumnOption
                            v-for="country in visibleCountries"
                            :key="country.iso2"
                            :value="country.iso2"
                        >
                            {{ country.emojiIcon }} +{{ country.dialCode }} {{ country.name }}
                        </IonPickerColumnOption>
                    </IonPickerColumn>
                </IonPicker>
            </IonContent>
        </IonModal>
    </div>
</template>

<script>
import Fuse from "fuse.js";
import {
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPicker,
    IonPickerColumn,
    IonPickerColumnOption,
    IonSearchbar,
    IonModal,
    IonItemGroup,
    IonContent, IonIcon, IonToolbar, IonBackButton, IonHeader, IonButton, IonButtons, IonTitle,
} from "@ionic/vue";
import { countries } from "@/utilities/Countries";

export default {
    name: "MobileNumberInput",
    emits: [
        "update:mobileNumber",
        "update:countryIso",
        "update:dialCode",
    ],
    components: {
        IonTitle, IonButtons, IonButton, IonHeader, IonBackButton, IonToolbar, IonIcon,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        IonPicker,
        IonPickerColumn,
        IonPickerColumnOption,
        IonInput,
        IonSearchbar,
        IonModal,
        IonItemGroup,
    },
    props: {
        mobileNumber: {
            type: String,
        },
        countryIso: {
            type: String,
        },
        dialCode: {
            type: String,
        },
    },
    data () {
        return {
            localMobileNumber: this.mobileNumber,
            localSelectedCountryIso: this.countryIso,
            dialCodePickerModalIsOpen: false,
            searchQuery: "",
            visibleCountries: countries,
        };
    },
    computed: {
        selectedCountry () {
            return countries.find((country) => country.iso2.toUpperCase() === this.countryIso.toUpperCase());
        },

        visibleDialCode () {
            const { emojiIcon, dialCode, } = this.selectedCountry;

            return `${emojiIcon} +${dialCode}`;
        },

        fuse () {
            return new Fuse(countries, {
                keys: [
                    {
                        name: "name",
                        getFn (country) {
                            return country.name;
                        },
                    },
                    {
                        name: "dialCode",
                        getFn (country) {
                            return `+${country.dialCode}`;
                        },
                    },
                ],
            });
        },
    },
    methods: {
        onCountryIsoChanged (e) {
            this.localSelectedCountryIso = e.detail.value;
        },

        openDialCodePickerModal () {
            this.dialCodePickerModalIsOpen = true;
        },

        closeDialCodePickerModal () {
            this.dialCodePickerModalIsOpen = false;
            this.searchQuery = "";
        },

        confirmDialCode () {
            const dialCode =
                countries.find((country) => country.iso2.toUpperCase() === this.localSelectedCountryIso.toUpperCase()).dialCode;

            this.$emit("update:countryIso", this.localSelectedCountryIso);
            this.$emit("update:dialCode", dialCode);
        },

        dismissDialCodePickerModal () {
            this.closeDialCodePickerModal();
        },

        search () {
            if (!this.searchQuery) {
                this.visibleCountries = countries;

                return;
            }

            this.visibleCountries =
                this.fuse.search(this.searchQuery).map((item) => item.item);
        },
    },
    watch: {
        searchQuery (value) {
            this.search(); // TODO: Debounce

            if (value) {
                this.localSelectedCountryIso = this.visibleCountries[0]?.iso2 ?? "";
            }
        },

        mobileNumber (value) {
            this.localMobileNumber = value;
        },

        localMobileNumber (value) {
            this.$emit("update:mobileNumber", value);
        },

        localSelectedCountryIso () {
            this.confirmDialCode();
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.mobile-number-input {

}

::v-deep(.dial-code-input) {
    cursor: pointer;

    * {
        //--padding-start: 16px;
        cursor: pointer !important;
        pointer-events: none !important;
        opacity: 1 !important;

        color: rgb(0, 0, 0) !important;
    }
}

.number-input {
    --padding-start: 0;
}

.dial-code-picker-modal {
    &::part(handle) {
        outline: none;
    }
}

.searchbar {
    margin: 11px 0 0 0;
}
</style>
