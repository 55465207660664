<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton v-if="actualStep === 1"/>
                    <IonBackButton default-href="#" :disabled="isWaitingServerResponse" @click="onBackButtonClick" v-else/>
                </IonButtons>
                <IonTitle>Organization Sign Up</IonTitle>
                <IonButtons slot="end">
                    <IonButton :disabled="!canNext" @click="nextStep">
                        Next
                        <IonIcon :icon="chevronForward"/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonToolbar>
                <!-- <progress-bar> -->
                <div class="flex flex--100">
                    <SimpleProgressBar :progress="progress"/>
                </div>
                <!-- </progress-bar> -->
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonItemGroup v-show="actualStep === 1">
                <!-- <company-name> -->
                <IonItem>
                    <IonInput
                        v-model="creator.companyName"
                        label="Company Name"
                        label-placement="floating"
                    ></IonInput>
                </IonItem>
                <IonItem lines="none" class="input-error-section" v-show="validations.companyNameIsMissing">
                    <span class="input-error-text">Company name is required</span>
                </IonItem>
                <!-- </company-name> -->
                <!-- <company-legal-name> -->
                <IonItem>
                    <IonInput
                        v-model="creator.companyLegalName"
                        label="Company Legal Name"
                        label-placement="floating"
                    ></IonInput>
                </IonItem>
                <IonItem lines="none" class="input-error-section" v-show="validations.companyLegalNameIsMissing">
                    <span class="input-error-text">Company legal name is required</span>
                </IonItem>
                <!-- </company-legal-name> -->
                <!-- <company-number> -->
                <IonItem>
                    <IonInput
                        v-model="creator.companyNumber"
                        label="Company Number"
                        label-placement="floating"
                    ></IonInput>
                </IonItem>
                <IonItem lines="none" class="input-error-section" v-show="validations.companyNumberIsMissing">
                    <span class="input-error-text">Company number is required</span>
                </IonItem>
                <!-- </company-number> -->
                <!-- <company-size> -->
                <IonItem>
                    <IonSelect
                        v-model="creator.companySizeType"
                        label="Company Size"
                        label-placement="floating"
                        interface="action-sheet"
                    >
                        <IonSelectOption
                            v-for="value in Object.values(CreatorOrganizationSizeType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`organizationSizeType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <!-- </company-size> -->
            </IonItemGroup>
            <IonItemGroup v-show="actualStep === 2">
                <!-- <email> -->
                <IonItem>
                    <IonInput
                        v-model="creator.email"
                        label="Company Email"
                        label-placement="floating"
                    ></IonInput>
                </IonItem>
                <IonItem lines="none" class="input-error-section" v-show="validations.emailIsMissing">
                    <span class="input-error-text">Company email is required</span>
                </IonItem>
                <!-- </email> -->
                <!-- <website-uri> -->
                <IonItem>
                    <IonInput
                        v-model="creator.websiteUri"
                        label="Company Website"
                        label-placement="floating"
                    ></IonInput>
                </IonItem>
                <IonItem lines="none" class="input-error-section" v-show="validations.websiteUriIsMissing">
                    <span class="input-error-text">Company website is required</span>
                </IonItem>
                <!-- </website-uri> -->
                <!-- <country> -->
                <CountryInput v-model:country-iso="creator.baseCountryIso" label="Company Country"/>
                <!-- </country> -->
                <!-- <industry> -->
                <IonItem>
                    <IonSelect v-model="creator.industryType" label="Industry" label-placement="floating" interface="action-sheet">
                        <IonSelectOption
                            v-for="value in Object.values(IndustryType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`industryType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <!-- </industry> -->
            </IonItemGroup>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonModal,
    IonNote,
    IonPage,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { userManager, } from "@/UserManager";

import { chevronForward, } from "ionicons/icons";
import ApplicationLogo from "@/components/ApplicationLogo.vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import SimpleCategorySelector from "@/components/categories/SimpleCategorySelector.vue";
import { CreatorOrganizationSizeType, IndustryType, presentMessageModal } from "@/utilities/Utilities";
import SimpleProgressBar from "@/components/mixed/SimpleProgressBar.vue";
import CountryInput from "@/components/inputs/CountryInput.vue";

export default {
    name: "OrganizationSignUpView",
    components: {
        CountryInput,
        SimpleProgressBar,
        SimpleCategorySelector,
        ApplicationLogo,
        IonPage,
        IonContent,
        IonItem,
        IonLabel,
        IonInput,
        IonDatetime,
        IonDatetimeButton,
        IonModal,
        IonSelect,
        IonSelectOption,
        IonItemGroup,
        IonProgressBar,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButton,
        IonBackButton,
        IonButtons,
        IonIcon,
        IonNote,
    },

    data () {
        return {
            isWaitingServerResponse: false,
            actualStep: 1,
            totalSteps: 2,
            categories: [],
            creator: {
                type: 0,
                email: "",
                websiteUri: "",
                baseCountryIso: "",
                industryType: "",
                companyName: "",
                companyLegalName: "",
                companyNumber: "",
                companySizeType: "",
            },
            validations: {
                companyNameIsMissing: undefined,
                companyLegalNameIsMissing: undefined,
                companyNumberIsMissing: undefined,
                emailIsMissing: undefined,
                websiteUriIsMissing: undefined,
            },
        };
    },

    computed: {
        IndustryType() {
            return IndustryType
        },
        CreatorOrganizationSizeType() {
            return CreatorOrganizationSizeType
        },
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        progress () {
            return this.actualStep / this.totalSteps;
        },

        canNext () {
            if (this.isWaitingServerResponse) {
                return false;
            }

            switch (this.actualStep) {
                case 1: {
                    return (
                        this.creator.companyName
                        && this.creator.companyLegalName
                        && this.creator.companyNumber
                        && Number.isFinite(this.creator.companySizeType)
                    );
                }
                case 2: {
                    return (
                        this.creator.email
                        && this.creator.websiteUri
                        && this.creator.baseCountryIso
                        && Number.isFinite(this.creator.industryType)
                    );
                }
            }

            return false;
        },

        chevronForward () {
            return chevronForward;
        },
    },

    methods: {
        onBackButtonClick (event) {
            event.stopImmediatePropagation();

            if (this.actualStep > 1) {
                --this.actualStep;
            }
            else {
                this.$router.back();
            }
        },

        toCreatorCongratsView () {
            this.$router.push("/sign-up/creator/congrats");
        },

        async nextStep () {
            if (this.actualStep === this.totalSteps) {
                await this.signUp();
            }
            else {
                ++this.actualStep;
            }
        },

        async signUp () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const response = await userManager.creatorSignUp(this.creator);
                this.isWaitingServerResponse = false;
                const loggedUser = response.result.user;

                switch (response.status) {
                    case "error": {
                        await presentMessageModal("Invalid data");

                        break;
                    }
                    case "ok": {
                        this.userStore.update(loggedUser);
                        this.toCreatorCongratsView();

                        break;
                    }
                }
            }
            catch (e) {
                console.log(e);

                this.isWaitingServerResponse = false;
            }
        },

        async fetch () {
            this.categories = await userManager.getAllCategories();
        },
    },
    created () {
        if (this.loggedUser) {
            this.creator.baseCountryIso = this.loggedUser.countryIso;
            this.creator.industryType = this.loggedUser.industryType;
        }

        this.fetch();
    },
    watch: {
        "creator.companyName" (value) {
            this.validations.companyNameIsMissing = !value;
        },

        "creator.companyLegalName" (value) {
            this.validations.companyLegalNameIsMissing = !value;
        },

        "creator.companyNumber" (value) {
            this.validations.companyNumberIsMissing = !value;
        },

        "creator.email" (value) {
            this.validations.emailIsMissing = !value;
        },

        "creator.websiteUri" (value) {
            this.validations.websiteUriIsMissing = !value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/utilities.scss";

.application-logo {
    width: 90vw;
    max-width: 142px;
    margin: 32px 10px;
}

.title {
    margin-top: 50px;

    font-size: 32px;
    font-weight: 600;
}

.note {
    margin: 2px 0 14px 0;

    font-size: 14px;
    font-weight: 400;
    color: rgb(150, 150, 150);

    text-align: center;
}

.input-date {
    margin-top: 30px;
}

.next-button {
    min-width: 320px;
    max-width: 80vw;
    margin-top: 40px;
}
</style>
