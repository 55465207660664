<template>
    <div class="flex flex--100 user-preview">
        <div class="flex flex--100 flex--y-align-center main-section">
            <UserAvatar class="user-avatar" :user="user"/>
            <div class="flex flex--100 user-header-section">
                <div class="flex flex--100">
                    <span class="flex flex--y-align-center user-name">
                        {{ visibleName }}
                        <VerifiedBadgeIcon class="badge-icon" v-if="isCreator"/>
                    </span>
                </div>
                <div class="flex flex--100">
                    <span class="flex followers-counter">
                        {{ visibleFollowers }} followers
                    </span>
                </div>
            </div>
            <div class="flex flex--100">
                <FollowButton
                    :value="isFollowed"
                    @click="onFollowButtonPressed"
                ></FollowButton>
            </div>
        </div>
    </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue";
import VerifiedBadgeIcon from "@/components/icons/VerifiedBadgeIcon.vue";
import FollowButton from "@/components/buttons/FollowButton.vue";
import { userManager } from "@/UserManager";
import { numberToCompactString } from "@/utilities/Utilities";
import { defineComponent } from "vue";

// TODO: No follow button if user is owner
export default defineComponent({
    name: "UserPreview",
    components: {
        FollowButton,
        VerifiedBadgeIcon,
        UserAvatar,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        visibleName () {
            return this.user.visibleName;
        },

        isCreator () {
            return this.user.isCreator;
        },

        visibleFollowers () {
            return numberToCompactString(this.user.metrics.followersCounter);
        },

        isFollowed () {
            return false;
        },
    },
    methods: {
        async onFollowButtonPressed (e) {
            e.stopImmediatePropagation();

            await this.toggleFollow();
        },

        async toggleFollow () {
            const { user, } = await userManager.toggleUserFollow(this.user.id);

            this.userStore.update(user);
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.user-preview {

}

.main-section {
    margin: 0;
    padding: 0;
}

.user-avatar {
    cursor: pointer;

    width: 42px;
    height: 42px;
}

.user-header-section {
    flex: 1;

    margin: 0 0 0 8px;
    padding: 0;
}

.user-name {
    cursor: pointer;

    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02rem;
    color: rgb(22, 22, 22);
}

.badge-icon {
    width: 18px;
    margin-left: 2px;

    fill: $brand-color;
}

.followers-counter {
    cursor: default;

    margin: 1px 0 0 0;
    padding: 0;

    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.02rem;
    color: rgb(122, 122, 122);
}

.follow-button {
    margin-top: 9px;
}
</style>

<style lang="scss">
ion-item > .user-preview {
    .main-section {
        margin: 13px 0;
    }
}
</style>
