<template>
    <div class="flex flex--100 website-main-navigation-bar">
        <div class="flex flex--100 flex--y-align-center primary-section">
            <div class="flex flex--40 left-section">
                <ApplicationLogo @click="toHomePage"/>
            </div>
            <div class="flex flex--60 right-section">
                <div class="flex flex--100 flex--x-align-end flex--y-align-center desktop-navigation-section">
                    <div
                        class="navigation-item navigation-item--arrow products-navigation-item"
                        :class="productsNavigationItemModifiers"
                        @click="toggleProductsMenu"
                    >
                        Solutions
                    </div>
                    <div
                        class="navigation-item navigation-link"
                        :class="navigationLinkModifiers('/pricing')"
                        @click="toPricingView"
                    >
                        <ApplicationIcon/>
                        Pricing
                    </div>
                    <!--
                    <div class="navigation-item navigation-link" @click="toMissionView">
                        <ApplicationIcon/>
                        Mission
                    </div>
                    -->
                    <div
                        class="navigation-item navigation-link"
                        :class="navigationLinkModifiers('/request-demo')"
                        @click="toRequestDemoView"
                    >
                        <ApplicationIcon/>
                        Contact Sales
                    </div>
                    <div class="navigation-item navigation-button" @click="toMarketplaceView">
                        Try Vottify
                    </div>
                </div>
                <div class="flex flex--100 flex--x-align-end flex--y-align-center mobile-navigation-section">
                    <div class="flex mobile-navigation-button">
                        <HamburgerButton :is-active="superMenuIsActive" @click="toggleSuperMenu"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- <mobile-menu> -->
        <div class="flex flex--100 super-menu" :class="superMenuModifiers">
            <div class="flex flex--100 link-section">

                <div class="navigation-item navigation-link" @click="toProductsView">
                    <ApplicationIcon/>
                    Solutions
                </div>
                <div class="navigation-item navigation-link" @click="toPricingView">
                    <ApplicationIcon/>
                    Pricing
                </div>
                <!--
                <div class="navigation-item navigation-link" @click="toMissionView">
                    <ApplicationIcon/>
                    Mission
                </div>
                -->
                <div class="navigation-item navigation-link" @click="toRequestDemoView">
                    <ApplicationIcon/>
                    Contact Sales
                </div>
                <div class="navigation-item navigation-button" @click="toMarketplaceView">
                    Try Vottify
                </div>

            </div>
        </div>
        <!-- </mobile-menu> -->
        <!-- <products-menu> -->
        <div
            class="flex flex--100 flex--x-align-center products-menu"
            ref="mainProductMenu"
        >
            <div class="flex flex--100 products-menu-section" ref="mainProductMenuSection">
                <MainProductMenu/>
            </div>
        </div>
        <!-- </products-menu> -->
        <div
            class="flex flex--100 super-menu-overlay"
            :class="superMenuOverlayModifiers"
            @click="deactivateSuperMenu"
        ></div>
    </div>
</template>

<script>
import ApplicationLogo from "@/components/ApplicationLogo.vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import HamburgerButton from "@/components/buttons/HamburgerButton.vue";
import MainProductMenu from "@/components/website/WebsiteProductsMenu.vue";
import { APP_STORE_URI } from "@/utilities/Utilities";

export default {
    name: "MainNavigationBar",
    components: {
        MainProductMenu,
        HamburgerButton,
        ApplicationIcon,
        ApplicationLogo,
    },
    data () {
        return {
            superMenuIsActive: false,
            productsMenuIsActive: false,
        };
    },
    methods: {
        toHomePage () {
            this.$router.push("/");
            window.document.querySelector("ion-content")?.scrollToTop();
        },

        toMissionView () {
            this.$router.push("/mission");
        },

        toProductsView () {
            this.$router.push("/products");
        },

        toPricingView () {
            this.$router.push("/pricing");
        },

        toRequestDemoView () {
            this.$router.push("/request-demo");
        },

        toMarketplaceView () {
            window.open(APP_STORE_URI, "_blank");
        },

        navigationLinkModifiers (path) {
            return {
                "navigation-link--active": this.$router.currentRoute.value.path === path,
            };
        },

        toggleSuperMenu () {
            this.superMenuIsActive = !this.superMenuIsActive;
        },
        deactivateSuperMenu () {
            this.superMenuIsActive = false;

            this.hideProductMenu();
        },

        toggleProductsMenu () {
            if (this.productsMenuIsActive) {
                this.hideProductMenu();
            }
            else {
                this.showProductMenu();
            }
        },

        showProductMenu () {
            this.productsMenuIsActive = true;
            this.$refs.mainProductMenu.style.height =
                `${this.$refs.mainProductMenuSection.offsetHeight}px`;
        },

        hideProductMenu () {
            this.productsMenuIsActive = false;
            this.$refs.mainProductMenu.style.height = "0";
        },
    },
    computed: {
        superMenuModifiers () {
            return {
                "super-menu--active": this.superMenuIsActive,
            };
        },

        productsNavigationItemModifiers () {
            return {
                "navigation-item--arrow-active": this.productsMenuIsActive,
            };
        },

        superMenuOverlayModifiers () {
            return {
                "super-menu-overlay--active": this.superMenuIsActive || this.productsMenuIsActive,
            };
        },
    },
    watch: {
        $route () {
            this.hideProductMenu();
            this.deactivateSuperMenu();
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

$primary-menu-height: 113px;

.products-menu-section {
    max-width: 1381px;
}

.website-main-navigation-bar {
    position: sticky;
    top: 0;

    margin: 0;
    padding: 0;

    background-color: transparent;

    z-index: 10000000;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        height: 0.5px;

        background-color: rgb(220, 220, 220);
        z-index: 54;
    }
}

.primary-section {
    height: $primary-menu-height;

    margin: 0;
    padding: 0 128px;

    background-color: rgb(255, 255, 255);

    z-index: 3;
}

.navigation-item {
    position: relative;
    cursor: pointer;

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.05rem;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);

    &--arrow::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;

        width: 0;
        height: 0;
        border: solid rgb(0, 0, 0);
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;

        transform: translateY(-4.5px) rotate(45deg);
    }
    &--arrow-active::after {
        transform: translateY(0) rotate(-135deg);
    }
}
.products-navigation-item {
    margin: 0 20px;
    padding-right: 13px;
}

.navigation-link {
    transition: opacity 180ms;
    will-change: opacity;

    margin: 0 20px;
    user-select: none;

    text-decoration: none;

    position: relative;

    .application-icon {
        transition: opacity 180ms;
        will-change: opacity;

        position: absolute;
        left: 50%;
        top: 50%;

        width: 22px;

        opacity: 0;

        transform: translate(-50%, -42%);

        z-index: -1;
    }

    &:hover,
    &--active {
        opacity: 0.5;

        .application-icon {
            opacity: 0.8;
        }
    }
}

.navigation-button {
    transition: opacity 180ms;
    will-change: opacity;

    padding: 15px 30px;
    margin: 0 0 0 20px;

    background-image: linear-gradient(45deg, rgb(0, 0, 0) 60%, $brand-color);
    border-radius: 999px;

    font-weight: 600;
    color: rgb(255, 255, 255);

    &:hover {
        opacity: 0.5;
    }
}

.application-logo {
    cursor: pointer;

    width: 151px;
    height: auto;
}

.super-menu {
    will-change: height;
    transition: height 300ms;
    overflow: scroll;

    position: absolute;
    left: 0;
    top: $primary-menu-height;

    width: 100%;
    height: 0;
    background-color: rgb(255, 255, 255);

    z-index: 3;

    &--active {
        height: 60vh;
    }
}
.products-menu {
    will-change: height;
    transition: height 300ms ease-in-out;
    overflow: scroll;

    position: absolute;
    left: 0;
    top: $primary-menu-height;

    width: 100%;
    height: 0;

    background-color: rgb(255, 255, 255);

    z-index: 3;
}
.super-menu-overlay {
    will-change: opacity;
    pointer-events: none;
    transition: opacity 300ms;

    position: absolute;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.8);

    z-index: 2;

    opacity: 0;

    &--active {
        opacity: 1;
        pointer-events: initial;
    }
}

.mobile-navigation-section {
    display: none;
}

.link-section {
    max-width: 800px;
    padding: 0 60px;

    .application-icon {
        display: none;
    }

    .navigation-item {
        width: 100%;
        padding: 24px 40px;

        border-bottom: 1px solid rgb(244, 244, 244);
    }

    .navigation-item.navigation-button {
        border-bottom: none;
    }

    .navigation-button {
        margin-top: 24px;
    }
}

@media (max-width: $mobile-breaking-point) {
    .primary-section {
        padding: 0 60px;
    }

    .desktop-navigation-section {
        display: none;
    }

    .mobile-navigation-section {
        display: flex;
    }

    .left-section {
        width: 80%;
    }

    .right-section {
        width: 20%;
    }
}
</style>
