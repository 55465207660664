import BillingView from "@/components/views/billings/BillingView.vue";
import CreatorPlansView from "@/components/views/billings/CreatorPlansView.vue";
import MyInvoicesView from "@/components/views/billings/MyInvoicesView.vue";
import CategoryView from "@/components/views/categories/CategoryView.vue";
import DiscoverCategoriesView from "@/components/views/categories/DiscoverCategoriesView.vue";
import ChatView from "@/components/views/chats/ChatView.vue";
import CreatorSignUpCongratsView from "@/components/views/CreatorSignUpCongratsView.vue";
import CreatorSignUpView from "@/components/views/CreatorSignUpView.vue";
import FeedView from "@/components/views/FeedView.vue";
import MainTabView from "@/components/views/MainTabView.vue";
import MandatoryCategorySelectionView from "@/components/views/MandatoryCategorySelectionView.vue";
import MyIdentityView from "@/components/views/MyIdentityView.vue";
import MyPollsView from "@/components/views/MyPollsView.vue";
import MyVotesView from "@/components/views/MyVotesView.vue";
import NotificationsView from "@/components/views/NotificationsView.vue";
import OrganizationSignUpView from "@/components/views/OrganizationSignUpView.vue";
import PlaygroundView from "@/components/views/PlaygroundView.vue";
import NewPollView from "@/components/views/polls/NewPollView.vue";
import PollScreenshotView from "@/components/views/polls/PollScreenshotView.vue";
import PollStatisticsView from "@/components/views/polls/PollStatisticsView.vue";
import PollView from "@/components/views/polls/PollView.vue";
import PrivacySecurityView from "@/components/views/PrivacySecurityView.vue";
import PublicFigureSignUpView from "@/components/views/PublicFigureSignUpView.vue";
import SearchView from "@/components/views/SearchView.vue";
import MyCategoriesView from "@/components/views/settings/MyCategoriesView.vue";
import MyIntegrationsView from "@/components/views/settings/MyIntegrationsView.vue";
import MyPreferencesView from "@/components/views/settings/MyPreferencesView.vue";
import SettingsView from "@/components/views/SettingsView.vue";
import SignInView from "@/components/views/SignInView.vue";
import SignupView from "@/components/views/SignUpView.vue";
import SplashView from "@/components/views/SplashView.vue";
import UserView from "@/components/views/UserView.vue";
import DesktopSignUpView from "@/components/website/DesktopSignUpView.vue";
import ForCompaniesView from "@/components/website/ForCompaniesView.vue";
import ForPublicFiguresContentCreators from "@/components/website/ForCreatorsPublicFiguresView.vue";
import ForFoundationsView from "@/components/website/ForFoundationsView.vue";
import ForInternationalOrganisations from "@/components/website/ForInternationalOrganisationsView.vue";
import ForNongovernmentalOrganisationCharities from "@/components/website/ForNgosCharitiesView.vue";
import ForThinkTanksView from "@/components/website/ForThinkTanksView.vue";
import MissionView from "@/components/website/MissionView.vue";
import ProductsView from "@/components/website/ProductsView.vue";
import CreatorPlansLargeView from "@/components/website/WebsiteCreatorPlansView.vue";
import WebsiteFeedView from "@/components/website/WebsiteFeedView.vue";
import SiteHomeView from "@/components/website/WebsiteHomeView.vue";
import WebsiteMainTabView from "@/components/website/WebsiteMainTabView.vue";
import RequestDemoView from "@/components/website/WebsiteRequestDemoView.vue";
import WebsiteSearchView from "@/components/website/WebsiteSearchView.vue";
import { useUserStore } from "@/stores/UserStore";
import { isInApp } from "@/UpperState";
import { userManager } from "@/UserManager";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw, } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/d/sign-up",
        component: DesktopSignUpView,
    },
    {
        path: "/d/n",
        component: WebsiteMainTabView,
        children: [
            {
                path: "",
                redirect: "/d/n/feed",
            },
            {
                path: "feed",
                component: WebsiteFeedView,
            },
            {
                path: "search",
                component: WebsiteSearchView,
            },
            {
                path: "users/:id",
                component: UserView,
                props: true,
            },
        ],
    },
    {
        path: "/",
        component: isInApp ? SplashView : SiteHomeView,
    },
    {
        path: "/navigation/",
        component: MainTabView,
        children: [
            {
                path: "",
                redirect: "/navigation/feed",
            },
            {
                path: "feed",
                component: FeedView,
            },
            {
                path: "search",
                component: SearchView,
            },
            {
                path: "notifications",
                component: NotificationsView,
            },
            {
                path: "users/:id",
                component: UserView,
                props: true,
            },
            {
                path: "polls/:id",
                component: PollView,
                props: true,
            },
            {
                path: "polls/:id/statistics",
                component: PollStatisticsView,
                props: true,
            },
            {
                path: "categories/:id",
                component: CategoryView,
                props: true,
            },
        ],
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/users/:id",
        component: UserView,
        props: true,
    },
    {
        path: "/creator/plans",
        component: CreatorPlansView,
    },
    {
        path: "/chats/:id",
        component: ChatView,
        props: true,
    },

    // <sign-up>
    {
        path: "/sign-up",
        component: SignupView,
    },
    {
        path: "/sign-up/creator",
        component: CreatorSignUpView,
        meta: {
            requiresLoggedUser: true,
            requiresActiveCreatorSubscription: true,
        },
    },
    {
        path: "/sign-up/organization",
        component: OrganizationSignUpView,
        meta: {
            requiresLoggedUser: true,
            requiresActiveCreatorSubscription: true,
        },
    },
    {
        path: "/sign-up/public-figure",
        component: PublicFigureSignUpView,
        meta: {
            requiresLoggedUser: true,
            requiresActiveCreatorSubscription: true,
        },
    },
    {
        path: "/sign-up/creator/congrats",
        component: CreatorSignUpCongratsView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/categories-selection",
        component: MandatoryCategorySelectionView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    // </sign-up>

    {
        path: "/sign-in",
        component: SignInView,
        meta: {
            requiresAnonymousUser: true,
        },
    },

    {
        path: "/user/votes",
        component: MyVotesView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/user/polls",
        component: MyPollsView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/user/categories",
        component: MyCategoriesView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/user/identity",
        component: MyIdentityView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/user/invoices",
        component: MyInvoicesView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/user/billing",
        component: BillingView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/user/integrations",
        component: MyIntegrationsView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/privacy-and-security",
        component: PrivacySecurityView,
    },
    {
        path: "/polls/:id",
        component: PollView,
        props: true,
    },
    {
        path: "/polls/:id/screenshot",
        component: PollScreenshotView,
        props: true,
    },
    {
        path: "/poll/new",
        component: NewPollView,
        meta: {
            requiresLoggedUser: true,
            requiresCreatorUser: true,
            //requiresActiveCreatorSubscription: true,
        },
    },
    {
        path: "/categories/:id",
        component: CategoryView,
    },
    {
        path: "/settings",
        component: SettingsView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/settings/preferences",
        component: MyPreferencesView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/categories",
        component: DiscoverCategoriesView,
        meta: {
            requiresLoggedUser: true,
        },
    },
    {
        path: "/playground",
        component: PlaygroundView,
    },
    {
        path: "/splash",
        component: SplashView,
    },
    // <website>
    {
        path: "/website",
        component: SiteHomeView,
    },
    {
        path: "/pricing",
        component: CreatorPlansLargeView,
    },
    {
        path: "/products/for-companies",
        component: ForCompaniesView,
    },
    {
        path: "/products/for-ngos-and-charities",
        component: ForNongovernmentalOrganisationCharities,
    },
    {
        path: "/products/for-public-figures-and-content-creators",
        component: ForPublicFiguresContentCreators,
    },
    {
        path: "/products/for-foundations",
        component: ForFoundationsView,
    },
    {
        path: "/products/for-international-organisations",
        component: ForInternationalOrganisations,
    },
    {
        path: "/products/for-think-tanks",
        component: ForThinkTanksView,
    },
    {
        path: "/request-demo",
        component: RequestDemoView,
    },
    {
        path: "/products",
        component: ProductsView,
    },
    {
        path: "/mission",
        component: MissionView,
    },
    // </website>
];

const mainRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

mainRouter.beforeEach((to, from, next) => {
    const { user, } = useUserStore();

    if (to.meta.requiresAnonymousUser && to.meta.requiresLoggedInUser) {
        throw new Error("A route can't require a user to be anonymous and logged in at the same time");
    }

    if (to.meta.requiresAnonymousUser) {
        if (userManager.isLogged) {
            return next({ path: "/navigation/feed", });
        }
    }

    if (to.meta.requiresLoggedUser) {
        if (userManager.isLogged) {
            // <mandatory-actions>
            // <follow-categories>
            if (to.path !== "/categories-selection") {
                if (user.metrics.followedCategoriesCounter < 3) {
                    return next({ path: "/categories-selection", });
                }
            }
            // </follow-categories>
            // </mandatory-actions>
        }
        else {
            // Used to redirect user to requested route after successfull login.
            window.localStorage.setItem("SignInRedirect", to.fullPath);

            return next({ path: "/sign-in", });
        }
    }

    if (to.meta.requiresActiveCreatorSubscription) {
        if (userManager.isLogged) {
            if (user.billing.activeCreatorSubscriptionType === undefined) {
                return next({ path: "/creator/plans", });
            }
        }
    }

    if (to.meta.requiresCreatorUser) {
        if (userManager.isLogged && !user.isCreator) {
            return next({ path: "/sign-up/creator", });
        }
    }

    next();
});

export {
    mainRouter,
};
