<template>
    <IonPage>
    <IonContent>
        <!-- <header> -->
        <div class="flex flex--100 header-section">
            <div class="flex flex--100">
                <h1 class="title">Sign In to Vottify</h1>
            </div>
            <IonItemGroup class="flex flex--100">
                <!-- <mobile-number> -->
                <MobileNumberInput
                    v-model:mobile-number="localMobileNumber"
                    v-model:country-iso="countryIso"
                    v-model:dial-code="dialCode"
                ></MobileNumberInput>
                <IonItem lines="none" v-show="validations.mobileNumberIsMissing" class="input-error-section">
                    <span class="input-error-text">Mobile number is required</span>
                </IonItem>
                <IonItem lines="none" v-show="validations.mobileNumberIsNaN" class="input-error-section">
                    <span class="input-error-text">Mobile number must be a number</span>
                </IonItem>
                <!-- </mobile-number> -->
                <!-- <password> -->
                <IonItem class="flex flex--100">
                    <IonInput
                        type="password"
                        label="Password"
                        label-placement="floating"
                        autocomplete="current-password"
                        v-model="user.password"
                    ></IonInput>
                </IonItem>
                <IonItem lines="none" v-show="validations.passwordIsMissing" class="input-error-section">
                    <span class="input-error-text">Password is required</span>
                </IonItem>
                <!-- </password> -->
            </IonItemGroup>
        </div>
        <!-- </header> -->
        <!-- <action> -->
        <div class="flex flex--100 action-section">
            <div class="flex flex--100 flex--x-align-center">
                <IonButton
                    class="simple-button"
                    :disabled="isWaitingSignInServerResponse"
                    @click="signIn"
                >
                    Sign In
                </IonButton>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <span class="or-text">or</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <IonButton
                    class="simple-button"
                    fill="outline"
                    :disabled="isWaitingSignInServerResponse"
                    @click="toSignUpView"
                >
                    Sign Up
                </IonButton>
            </div>
        </div>
        <!-- </action> -->
    </IonContent>
    </IonPage>
</template>

<script>
import { IonButton, IonContent, IonInput, IonItem, IonItemGroup, IonLabel, IonPage, isPlatform, } from "@ionic/vue";
import { userManager, } from "@/UserManager";
import ApplicationLogo from "@/components/ApplicationLogo.vue";
import { mapStores, } from "pinia";
import { useUserStore, } from "@/stores/UserStore";
import MobileNumberInput from "@/components/inputs/MobileNumberInput";
import { presentMessageModal, } from "@/utilities/Utilities";

export default {
    name: "SignInView",
    components: {
        ApplicationLogo,
        IonPage,
        IonContent,
        IonItem,
        IonLabel,
        IonInput,
        IonItemGroup,
        IonButton,
        MobileNumberInput,
    },
    data () {
        return {
            isWaitingSignInServerResponse: false,
            localMobileNumber: "",
            countryIso: userManager.preferredCountry.iso2,
            dialCode: userManager.preferredCountry.dialCode,
            user: {
                mobileNumber: "",
                password: "",
            },
            validations: {
                mobileNumberIsMissing: undefined,
                mobileNumberIsNaN: undefined,
                passwordIsMissing: undefined,
            },
        };
    },
    computed: {
        ...mapStores(useUserStore),

        mobileNumber () {
            return `+${this.dialCode}${this.localMobileNumber}`;
        },
    },
    methods: {
        toSignUpView () {
            this.$router.push("/sign-up");
        },

        toNavigationView () {
            this.$router.push("/navigation");
        },

        async signIn () {
            if (this.isWaitingSignInServerResponse) {
                return;
            }

            this.isWaitingSignInServerResponse = true;

            try {
                const response = await userManager.signIn(this.user);
                this.isWaitingSignInServerResponse = false;

                if (response.status === "error") {
                    if (response.meta.type === "UserNotFoundError") {
                        return await presentMessageModal("The user doesn't exist");
                    }
                    else if (response.meta.type === "IncorrectPasswordError") {
                        return await presentMessageModal("Incorrect credentials");
                    }

                    return await presentMessageModal("Invalid data");
                }

                const loggedUser = response.result.user;

                if (loggedUser) {
                    userManager.setPreferredCountryIso(this.countryIso);
                    this.userStore.update(loggedUser);
                    this.toNavigationView();
                }
            }
            catch (e) {
                this.isWaitingSignInServerResponse = false;
            }
        },
    },
    watch: {
        mobileNumber (value) {
            this.user.mobileNumber = value;
        },

        localMobileNumber (value) {
            this.validations.mobileNumberIsMissing = !value;
            this.validations.mobileNumberIsNaN = !Number.isFinite(Number(value));
        },

        "user.password" (value) {
            this.validations.passwordIsMissing = !value;
        },
    },
    ionViewDidLeave () {
        this.$resetState();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/utilities.scss";

.title {
    margin: 0 16px 8px 16px;

    font-size: 32px;
    font-weight: 800;
    color: $base-text-color;

    @media (prefers-color-scheme: dark) {
        //color: $dark-theme-text-color;
    }
}

.simple-button {
    min-width: 320px;
    max-width: 80vw;

    font-weight: 600;
}

.or-text {
    cursor: default;

    margin: 14px 0;

    font-size: 14px;
    font-weight: 600;
    color: rgb(150, 150, 150);

    -webkit-user-select: none;
    user-select: none;
}

.action-section {
    position: relative;

    margin-top: 52px;

    z-index: 1;
}

.header-section {
    position: relative;

    margin-top: calc(32px + env(safe-area-inset-top));

    z-index: 10;
}
</style>
