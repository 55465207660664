<template>
    <IonPage>
        <IonContent>
            <div class="flex flex--x-align-center flex--y-align-center main">
                <ApplicationIcon/>
            </div>
        </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, } from "@ionic/vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { notifySplashScreenEnded, } from "@/UpperState";

export default {
    name: "SplashView",
    components: {
        ApplicationIcon,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
    },
    methods: {
        toNextView () {
            let nextPath = "/navigation";

            try {
                if (window.location.protocol === "vottify:") {
                    const url = new URL(window.location.href);

                    nextPath = url.pathname;
                }
            }
            catch {
                // Silence is golden
            }

            this.$router.replace(nextPath);
        },
    },
    mounted () {
        setTimeout(async () => {
            // const launchDescriptor = await App.getLaunchUrl();
            // const launchUri = launchDescriptor?.url;

            notifySplashScreenEnded();
            this.toNextView();
        }, 1000);
    },
};
</script>

<style lang="scss" scoped>
.main {
    width: 100%;
    height: 100%;

    background-color: rgb(255, 255, 255);
}

.application-icon {
    will-change: transform;
    animation: splash 1s ease-in-out forwards;

    position: absolute;

    width: 64px;
    height: auto;

    @keyframes splash {
        0% {
            transform: scale3D(1, 1, 1);
        }
        50% {
            transform: scale3D(0.5, 0.5, 0.5);
        }
        100% {
            transform: scale3D(40, 40, 40);
        }
    }
}
</style>
