<template>
    <IonPage>
    <IonContent>
        <div class="flex flex--100 main-section">
            <div class="flex flex--100 feed-section">
                <!-- <feed-switcher> -->
                <div class="flex flex--100 flex--x-align-center flex--y-align-center feed-selection-section">
                    <span
                        class="feed-type-button"
                        :class="getFeedTypeButtonModifiers(FeedType.FOR_YOU)"
                        @click="selectFeed(FeedType.FOR_YOU)"
                    >
                        For You
                    </span>
                    <span
                        class="feed-type-button"
                        :class="getFeedTypeButtonModifiers(FeedType.RESULTS)"
                        @click="selectFeed(FeedType.RESULTS)"
                    >
                        Results
                        <span
                            class="feed-type-button__light"
                            v-show="hasVotedOpenPolls"
                        ></span>
                    </span>
                </div>
                <!-- </feed-switcher> -->
                <!-- <poll-swiper> -->
                <div class="flex flex--100 poll-swiper-section" v-if="visiblePolls.length > 0">
                    <PollSwiper
                        :key="pollSwiperKey"
                        :polls="visiblePolls"
                        v-model:index="pollSwiperIndex"
                        @swiped="onPollSwiped"
                        @poll-voted="onPollVoted"
                    />
                </div>
                <!-- </poll-swiper> -->
                <!-- <empty-feed> -->
                <div
                    class="flex flex--100 flex--x-align-center flex--y-align-center empty-feed-section"
                    v-show="!isWaitingFeedServerResponse && visiblePolls.length === 0"
                >
                    <h2 class="empty-feed-section__title">
                        You've reached the end!
                    </h2>
                </div>
                <!-- </empty-feed> -->
            </div>
        </div>
    </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonIcon, IonPage, IonRefresher, IonRefresherContent, IonSpinner, IonToggle, } from "@ionic/vue";
import { userManager, } from "@/UserManager";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import Poll from "@/components/polls/SimplePoll.vue";
import LiveIcon from "@/components/icons/LiveIcon.vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { pollsStore } from "@/main";
import PollSwiper from "@/components/polls/PollSwiper.vue";
import { FeedType, } from "@/utilities/Utilities";
import { useUtilityStore } from "@/stores/UtilityStore";

export default {
    name: "FeedView",
    components: {
        IonSpinner,
        PollSwiper,
        ApplicationIcon,
        LiveIcon,
        Poll,
        IonPage,
        IonContent,
        IonRefresher,
        IonRefresherContent,
        IonIcon,
        IonToggle,
    },
    data () {
        return {
            isWaitingFeedServerResponse: false,
            selectedFeed: FeedType.FOR_YOU,
            forYouFeed: [],
            forYouFeedIndex: 0,
            resultsFeed: [],
            resultsFeedIndex: 0,
            pollSwiperKey: Math.random(),

            mustShowPollSwiperGuide: !window.localStorage.getItem("UserConfirmedPollSwiperGuide"),
            pollSwiperGuideTimeoutId: undefined,
            mustShowPollResultsGuide: !window.localStorage.getItem("UserConfirmedPollResultsGuide"),
            pollResultsGuideTimeoutId: undefined,
        };
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapStores(useUtilityStore),

        FeedType () {
            return FeedType;
        },

        pollSwiperIndex: {
            get () {
                switch (this.selectedFeed) {
                    case FeedType.FOR_YOU: {
                        return this.forYouFeedIndex;
                    }
                    case FeedType.RESULTS: {
                        return this.resultsFeedIndex;
                    }
                }

                return 0;
            },
            set (value) {
                switch (this.selectedFeed) {
                    case FeedType.FOR_YOU: {
                        this.forYouFeedIndex = value;

                        break;
                    }
                    case FeedType.RESULTS: {
                        this.resultsFeedIndex = value;

                        break;
                    }
                }
            }
        },

        loggedUser () {
            return this.userStore.user;
        },

        hasVotedOpenPolls () {
            return this.loggedUser.$user.hasVotedOpenPolls;
        },

        visiblePolls () {
            switch (this.selectedFeed) {
                case FeedType.FOR_YOU: {
                    return this.forYouFeed;
                }
                case FeedType.RESULTS: {
                    return this.resultsFeed;
                }
            }

            return [];
        },
    },
    methods: {
        selectFeed (feedType) {
            if (this.isWaitingFeedServerResponse) {
                return;
            }

            this.selectedFeed = feedType;

            this.fetch();
        },

        confirmPollSwiperGuide () {
            window.localStorage.setItem("UserConfirmedPollSwiperGuide", "true");
            this.utilityStore.sharedComponents.pollSwiperGuide.hide();

            this.mustShowPollSwiperGuide = false;
            this.pollSwiperGuideTimeoutId = undefined;
        },

        confirmPollResultsGuide () {
            window.localStorage.setItem("UserConfirmedPollResultsGuide", "true");
            this.utilityStore.sharedComponents.pollResultsGuide.hide();

            this.mustShowPollResultsGuide = false;
            this.pollResultsGuideTimeoutId = undefined;
        },

        onPollSwiped () {
            if (this.mustShowPollSwiperGuide) {
                this.confirmPollSwiperGuide();
            }
        },

        onPollVoted () {
            if (this.mustShowPollResultsGuide && !this.pollResultsGuideTimeoutId) {
                this.pollResultsGuideTimeoutId = setTimeout(() => {
                    this.utilityStore.sharedComponents.pollResultsGuide.show();
                }, 3000);
            }
        },

        getFeedTypeButtonModifiers (feedType) {
            return {
                "feed-type-button--selected": this.selectedFeed === feedType,
            };
        },

        async fetch () {
            this.isWaitingFeedServerResponse = true;

            switch (this.selectedFeed) {
                case FeedType.FOR_YOU: {
                    this.forYouFeed = [];
                    const {
                        forYouFeed,
                    } = await userManager.getForYouFeed();

                    pollsStore.updateMany(forYouFeed);

                    this.forYouFeed = forYouFeed;

                    break;
                }
                case FeedType.RESULTS: {
                    this.resultsFeed = [];
                    const {
                        resultsFeed,
                    } = await userManager.getResultsFeed();

                    pollsStore.updateMany(resultsFeed);

                    this.resultsFeed = resultsFeed;

                    break;
                }
            }

            this.pollSwiperKey = Math.random();
            this.forYouFeedIndex = 0;
            this.resultsFeedIndex = 0;
            this.isWaitingFeedServerResponse = false;

            if (this.visiblePolls.length > 1 && this.mustShowPollSwiperGuide && !this.pollSwiperGuideTimeoutId) {
                this.pollSwiperGuideTimeoutId = setTimeout(() => {
                    this.utilityStore.sharedComponents.pollSwiperGuide.show();
                }, 3000);
            }
        },
    },
    created () {
        this.fetch();
    },
    watch: {
        selectedFeed (selectedFeed) {
            if (this.mustShowPollResultsGuide) {
                if (selectedFeed === FeedType.RESULTS) {
                    this.confirmPollResultsGuide();
                }
            }
        },
    },
    mounted () {
        window.document.addEventListener("click", () => {
            if (this.utilityStore.sharedComponents.pollSwiperGuide.isOpen) {
                this.confirmPollSwiperGuide();
            }

            if (this.utilityStore.sharedComponents.pollResultsGuide.isOpen) {
                this.confirmPollResultsGuide();
            }
        });
    },
    ionViewDidLeave () {
        if (this.pollSwiperGuideTimeoutId) {
            clearTimeout(this.pollSwiperGuideTimeoutId);

            this.pollSwiperGuideTimeoutId = undefined;
        }

        if (this.pollResultsGuideTimeoutId) {
            clearTimeout(this.pollResultsGuideTimeoutId);

            this.pollResultsGuideTimeoutId = undefined;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/globals.scss";
@import "@/theme/palette.scss";
@import "@/theme/mixins.scss";

@include disable-global-scrolling;

.feed-type-button {
    transition:
        color 190ms,
        background-color 190ms,
        border-color 190ms;

    cursor: pointer;
    user-select: none;

    position: relative;

    margin: 0 7px;
    padding: 6px 27px;

    background-color: rgb(14, 14, 16);
    border: 1.5px solid rgb(38, 38, 40);
    border-radius: 999px;

    font-weight: 600;
    letter-spacing: 0.02rem;
    font-size: 13px;
    color: rgb(110, 110, 110);

    &--selected {
        background-color: rgb(19, 19, 20);
        border-color: rgb(90, 89, 91);

        color: rgb(255, 255, 255);
    }

    &__light {
        position: absolute;
        right: -3px;
        top: -3px;

        width: 13px;
        height: 13px;

        background-color: rgb(255, 255, 255);
        border: 5px solid rgb(234, 113, 12);
        box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.002);
        border-radius: 50%;
    }
}

.feed-selection-section {
    position: absolute;
    left: 0;
    top: env(safe-area-inset-top);

    height: $feed-selection-section-height;

    background-color: transparent;

    z-index: 10;
}

.main-section {
    height: 100%;

    background-color: rgb(0, 0, 0);
}

.feed-section {
    height: 100%;
}

.poll-swiper {
    will-change: opacity;
    animation-name: fade-in;
    animation-duration: 333ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    opacity: 0;

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.poll-swiper-section {
    --required-margin-top: env(safe-area-inset-top);
    --required-margin-bottom: env(safe-area-inset-bottom);

    overflow: visible;

    height: calc(
        100vh
        - #{$feed-selection-section-height}
        - #{$main-bottom-navigation-height}
        - var(--required-margin-top)
        - var(--required-margin-bottom)
    );
    margin-top: calc(
        #{$feed-selection-section-height}
        + var(--required-margin-top)
    );

    background-color: transparent;
}

.empty-feed-section {
    cursor: default;
    user-select: none;
    overflow: hidden;

    height: 100%;

    &__title {
        margin: 21px;

        font-weight: 600;
        letter-spacing: 0.01rem;
        color: rgb(255, 255, 255);
        text-align: center;
    }
}
</style>
