<template>
    <div class="flex flex--100 search-feed">
        <div class="flex flex--100 flex--y-align-center header">
            <TrendingIcon/>
            <h2 class="title">Trending</h2>
        </div>
        <div class="flex flex--100">
            <SimplePollSquarePreview
                v-for="{ id, } in searchFeed"
                :key="id"
                :id="id"
                @click="toPollView(id)"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import { userManager } from "@/UserManager";
import SimplePollSquarePreview from "@/components/polls/SimplePollSquarePreview.vue";
import { mapStores } from "pinia";
import { usePollsStore } from "@/stores/PollsStore";
import TrendingIcon from "@/components/icons/TrendingIcon.vue";

export default defineComponent({
    name: "SearchFeed",
    components: {TrendingIcon, SimplePollSquarePreview},
    data () {
        return {
            isWaitingServerResponse: false,
            searchFeed: [],
        };
    },
    computed: {
        ...mapStores(usePollsStore),
    },
    methods: {
        toPollView (pollId) {
            this.$router.push(`/navigation/polls/${pollId}`);
        },

        async fetch () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;
            const response = await userManager.feeds.getSearchFeed();
            const { searchFeed, } = response.data.result;
            this.isWaitingServerResponse = false;

            this.pollsStore.updateMany(searchFeed);

            this.searchFeed = searchFeed;
        },
    },
    created () {
        this.fetch();
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.header {
    padding: 11px;

    border-bottom: $base-border;
}

.trending-icon {
    width: 27px;

    color: $base-text-color;
}

.title {
    margin: 0 0 0 5px;
    padding: 0;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.01rem;
    color: $base-text-color;
}
</style>
