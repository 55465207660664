<template>
    <div class="flex flex--100 simple-poll" :class="modifiers" ref="root">
        <!-- <creator> -->
        <div class="flex flex--100 flex--y-align-center creator-section">
            <UserAvatar class="creator-avatar" :user="createdBy" @click="toCreatorView"/>
            <!-- <creator-header> -->
            <div class="flex creator-header-section">
                <div class="flex flex--100">
                    <span class="flex flex--y-align-center creator-name" @click="toCreatorView">
                        {{ visibleCreatorName }}
                        <VerifiedBadgeIcon class="badge-icon"/>
                    </span>
                </div>
                <div class="flex flex--100">
                    <span class="flex creator-followers" @click="toCreatorView">
                        {{ visibleCreatorFollowers }} followers
                    </span>
                </div>
                <div class="flex flex--100">
                    <span class="flex poll-creation-date">
                        {{ visibleCreatedAt }} ago
                    </span>
                </div>
            </div>
            <!-- </creator-header> -->
        </div>
        <!-- </creator> -->
        <div class="flex flex--100 main-section">
            <!-- <question> -->
            <div class="flex flex--100 question-section">
                <span class="question" @click="toPollView">{{ question }}</span>
            </div>
            <!-- </question> -->
            <!-- <description> -->
            <div class="flex flex--100 description-section" v-if="visibleDescription">
                <p class="description" @click="toPollView">{{ visibleDescription }}</p>
            </div>
            <!-- </description> -->
            <!-- <options> -->
            <div class="flex flex--100 options-section">
                <div
                    class="flex flex--100 option-section"
                    v-for="(option, i) in options"
                    :key="i"
                >
                    <div
                        class="flex option"
                        :class="getOptionModifiers(i)"
                        :style="getOptionStyle(i)"
                        @click="vote(i)"
                    >
                        <span class="option-text">
                            <!-- TODO: Separate text from emojis in a computed property, then invert the emojis filter -->
                            {{ option }}
                        </span>
                        <span class="option-tick" v-if="loggedUserVotedOptionIndex === i">
                            <ApplicationIcon class="option-tick__icon"/>
                        </span>
                    </div>
                    <div class="votes-percentage" v-if="votesAreVisible">
                        {{ getOptionVotesPercentage(i) }}%
                    </div>
                </div>
            </div>
            <!-- </options> -->
            <!-- <privacy> -->
            <div class="flex flex--100 privacy-section">
                <div class="privacy">
                    Your vote is anonymous.
                    <span class="privacy-link" @click="openPollFaqModal">Learn more</span>.
                </div>
            </div>
            <!-- </privacy> -->
            <!-- <action> -->
            <div class="flex flex--100 action-section" v-if="hasAction">
                <SimplePollActionButton :poll-id="this.id"/>
            </div>
            <!-- </action> -->
            <!-- <metrics> -->
            <div class="flex flex--100 flex--y-align-center metrics-section">
                <div class="flex flex--50">
                    <span class="flex metric">
                        {{ visibleTotalVotesCounter }} · {{ visibleRemainingTime }}
                    </span>
                </div>
                <div class="flex flex--50 flex--x-align-end">
                    <span class="flex metric">{{ visibleViewsCounter }}</span>
                </div>
            </div>
            <!-- </metrics> -->
            <!-- <bottom> -->
            <div class="flex flex--100 flex--y-align-center bottom-section" v-if="showCategories">
                <!-- <comment-button> -->
                <div class="flex flex--y-align-center secondary-action-button" @click="openCommentsModal">
                    <CommentIcon class="secondary-action-button__icon"/>
                    <span class="secondary-action-button__label">{{ visibleCommentsCounter }}</span>
                </div>
                <!-- </comment-button> -->
                <!-- <share-button> -->
                <div class="flex flex--y-align-center secondary-action-button" @click="share">
                    <SendMessageIcon class="secondary-action-button__icon"/>
                    <span class="secondary-action-button__label">{{ visibleSharesCounter }}</span>
                </div>
                <!-- </share-button> -->
                <div class="flex flex--100 categories-section">
                    <div
                        v-for="{ id, alternativeId, } in reversedCategories"
                        :key="id"
                        class="flex flex--x-align-center flex--y-align-center category"
                        @click="openCategoriesModal"
                    >
                        <SimpleCategoryIcon :alternativeId="alternativeId"/>
                    </div>
                </div>
            </div>
            <!-- </bottom> -->
        </div>
    </div>
</template>

<script>
import { userManager, } from "@/UserManager";
import VerifiedBadgeIcon from "@/components/icons/VerifiedBadgeIcon.vue";
import { timeToCompactString, } from "@/utilities/Date";
import { numberToCompactString, PollStatusType, } from "@/utilities/Utilities";
import CommentIcon from "@/components/icons/CommentIcon.vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { pollsStore, userStore, } from "@/main";
import UserAvatar from "@/components/UserAvatar.vue";
import { Share } from "@capacitor/share";
import SimpleCategoryIcon from "@/components/categories/SimpleCategoryIcon.vue";
import { Haptics, ImpactStyle, } from "@capacitor/haptics";
import { IonContent, IonHeader, IonModal, IonTitle, IonToolbar, } from "@ionic/vue";
import SimpleCategory from "@/components/categories/SimpleCategory.vue";
import { cloneDeep } from "lodash/lang";
import { mapStores } from "pinia";
import { useUtilityStore } from "@/stores/UtilityStore";
import SimplePollActionButton from "@/components/polls/SimplePollActionButton.vue";
import SendMessageIcon from "@/components/icons/SendMessageIcon.vue";

const autoUpdater = {
    isEnabled: true,
    intervalMs: 5000,
};

const requiredViewDurationMs = 3000;

export default {
    name: "SimplePoll",
    components: {
        SendMessageIcon,
        SimplePollActionButton,
        SimpleCategory,
        IonModal, SimpleCategoryIcon, UserAvatar, ApplicationIcon, CommentIcon, VerifiedBadgeIcon,
        IonHeader, IonToolbar, IonTitle, IonContent,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        showCategories: {
            type: Boolean,
            default: true,
        },
        descriptionMaxLength: {
            type: Number,
            default: -1,
        },
        collectMetrics: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            isWaitingVoteResponse: false,

            autoUpdaterObserver: undefined,
            autoUpdaterIntervalId: undefined,

            impressionMetricObserver: undefined,

            viewMetricObserver: undefined,
            viewMetricObserverTimeoutId: undefined,
        };
    },
    computed: {
        ...mapStores(useUtilityStore),

        poll () {
            return pollsStore.get(this.id);
        },

        loggedUserVote () {
            return this.poll?.$user?.vote;
        },

        loggedUserHasVoted () {
            return !!this.loggedUserVote;
        },

        loggedUserVotedOptionIndex () {
            if (!this.loggedUserHasVoted) {
                return undefined;
            }

            return this.loggedUserVote.optionIndex;
        },

        modifiers () {
            return {
                "simple-poll--closed": this.isClosed,
                "simple-poll--visible-votes": this.votesAreVisible,
            };
        },

        reversedCategories () {
            const categories = cloneDeep(this.poll.categories);

            return categories.reverse();
        },

        hasAction () {
            return this.poll.actionType !== undefined;
        },

        createdBy () {
            return this.poll.createdBy;
        },

        votesAreVisible () {
            return this.isClosed || this.loggedUserHasVoted;
        },

        question () {
            return this.poll.question;
        },

        visibleDescription () {
            let visibleDescription = this.poll.description;

            if (!visibleDescription) {
                return "";
            }

            if (this.descriptionMaxLength === -1) {
                return visibleDescription;
            }

            visibleDescription =
                visibleDescription.substring(0, this.descriptionMaxLength).trim();

            while ([ ".", "?", "!", ].includes(visibleDescription.at(-1))) {
                visibleDescription =
                    visibleDescription.substring(0, visibleDescription.length - 1);
            }

            return `${visibleDescription.trim()}...`;
        },

        metrics () {
            return this.poll.metrics;
        },

        // Total votes counter
        totalVotesCounter () {
            let totalVotesCounter = 0;

            for (const optionVotesCounter of this.votesCounters) {
                totalVotesCounter += optionVotesCounter;
            }

            return totalVotesCounter;
        },

        // Votes counters for each option
        votesCounters () {
            return this.metrics.votesCounters;
        },

        options () {
            return this.poll.options;
        },

        createdAt () {
            return new Date(this.poll.createdAt);
        },

        publishAt () {
            return new Date(this.poll.publishAt);
        },

        expireAt () {
            return new Date(this.poll.expireAt);
        },

        viewsCounter () {
            return this.metrics.viewsCounter;
        },

        commentsCounter () {
            return this.metrics.commentsCounter;
        },

        sharesCounter () {
            return this.metrics.sharesCounter;
        },

        isPending () {
            return this.publishAt.getTime() > Date.now();
        },

        isClosed () {
            return Date.now() >= this.expireAt.getTime();
        },

        statusType () {
            if (this.isPending) {
                return PollStatusType.PENDING;
            }

            if (this.isClosed) {
                return PollStatusType.CLOSED;
            }

            return PollStatusType.OPEN;
        },

        remainingTimeMs () {
            return this.expireAt.getTime() - Date.now();
        },

        visibleCreatedAt () {
            return timeToCompactString(Date.now() - this.createdAt.getTime());
        },

        visibleRemainingTime () {
            const ms = this.remainingTimeMs;

            if (ms <= 0) {
                return "Final results";
            }

            return `${timeToCompactString(ms)} left`;
        },

        visibleTotalVotesCounter () {
            return `${numberToCompactString(this.totalVotesCounter)} votes`;
        },

        visibleCommentsCounter () {
            return numberToCompactString(this.commentsCounter);
        },

        visibleSharesCounter () {
            return numberToCompactString(this.sharesCounter);
        },

        visibleViewsCounter () {
            return `${numberToCompactString(this.viewsCounter)} views`;
        },

        visibleCreatorName () {
            return this.createdBy.visibleName;
        },

        visibleCreatorFollowers () {
            return numberToCompactString(this.createdBy.metrics.followersCounter);
        },

        sharedComponents () {
            return this.utilityStore.sharedComponents;
        },
    },
    methods: {
        openCategoriesModal () {
            this.sharedComponents.categoriesModal.openModal(this.poll.categories);
        },

        openPollFaqModal () {
            this.sharedComponents.pollFaqModal.openModal();
        },

        openCommentsModal () {
            this.sharedComponents.pollCommentsModal.openModal(this.id);
        },

        async vibrate () {
            try {
                await Haptics.impact({
                    style: ImpactStyle.Medium,
                });
            }
            catch {
                // Silence is golden
            }
        },

        async vote (optionIndex) {
            const currentVotedOptionIndex =
                this.loggedUserVotedOptionIndex;
            let hasVoted = false;

            if (this.isWaitingVoteResponse) {
                return;
            }

            if (this.isClosed) {
                return;
            }

            if (
                Number.isFinite(currentVotedOptionIndex)
                && currentVotedOptionIndex === optionIndex
            ) {
                return;
            }

            this.isWaitingVoteResponse = true;

            try {
                const {
                    user,
                    poll,
                } = this.loggedUserHasVoted
                    ? await userManager.amendPollVote(this.id, currentVotedOptionIndex, optionIndex)
                    : await userManager.votePoll(this.id, optionIndex);

                userStore.update(user);
                pollsStore.update(poll);

                hasVoted = true;
            }
            catch {
                // Silence is golden
            }
            finally {
                this.isWaitingVoteResponse = false;
            }

            if (hasVoted) {
                this.$emit("voted");
                this.vibrate().then().catch((e) => e);
            }
        },

        getOptionModifiers (optionIndex) {
            return {
                "option--voted": this.loggedUserVotedOptionIndex === optionIndex,
            };
        },

        getOptionVotesPercentage (optionIndex) {
            const optionVotes = this.votesCounters[optionIndex];

            if (optionVotes === 0) {
                return 0;
            }

            return Number((optionVotes / this.totalVotesCounter * 100).toFixed(0));
        },

        getOptionStyle (optionIndex) {
            const style = {};

            if (this.votesAreVisible) {
                const votedPercentage = Math.max(this.getOptionVotesPercentage(optionIndex), 1);

                style["--background-width"] = `${votedPercentage}%`;
            }

            return style;
        },

        async fetchPoll () {
            pollsStore.update(await userManager.getPoll(this.id));
        },

        async hitImpression () {
            try {
                await userManager.hitPollImpression(this.id);
            }
            catch {
                // Silence is golden
            }
        },

        async hitView () {
            try {
                await userManager.hitPollView(this.id);
            }
            catch {
                // Silence is golden
            }
        },

        async hitShare () {
            try {
                await userManager.hitPollShare(this.id);
            }
            catch {
                // Silence is golden
            }
        },

        toCreatorView () {
            this.$router.push(`/navigation/users/${this.createdBy.id}`);
        },

        toPollView () {
            this.$router.push(`/navigation/polls/${this.id}`);
        },

        async share () {
            this.hitShare().then().catch((e) => e);

            try {
                await Share.share({
                    text: this.question,
                    url: `https://www.vottify.com/polls/${this.id}`,
                });
            }
            catch {
                // Silence is golden
            }
        },

        constructMetricsCollector () {
            // <auto-updater>
            if (autoUpdater.isEnabled) {
                const autoUpdaterObserver = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting === true) {
                        this.useUpdater();
                    }
                    else {
                        this.clearUpdaterInterval();
                    }
                }, {
                    root: window.document.body,
                    threshold: 0.8,
                });

                autoUpdaterObserver.observe(this.$refs.root);

                this.autoUpdaterObserver = autoUpdaterObserver;
            }
            // </auto-updater>

            // <impression>
            const impressionObserver = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting === true) {
                    impressionObserver.disconnect();
                    this.hitImpression();
                }
            }, {
                root: window.document.body,
                threshold: 0.8,
            });

            impressionObserver.observe(this.$refs.root);

            this.impressionMetricObserver = impressionObserver;
            // </impression>

            // <view>
            const viewObserver = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting === true) {
                    if (!this.viewMetricObserverTimeoutId) {
                        this.viewMetricObserverTimeoutId = setTimeout(() => {
                            viewObserver.disconnect();
                            this.hitView();
                        }, requiredViewDurationMs);
                    }
                }
                else {
                    this.clearViewObserverTimeout();
                }
            }, {
                root: window.document.body,
                threshold: 0.8,
            });

            viewObserver.observe(this.$refs.root);

            this.viewMetricObserver = viewObserver;
            // </view>
        },

        useUpdater () {
            if (!this.autoUpdaterIntervalId) {
                this.autoUpdaterIntervalId = setInterval(() => {
                    this.fetchPoll();
                }, autoUpdater.intervalMs);
            }
        },

        clearUpdaterInterval () {
            if (this.autoUpdaterIntervalId) {
                clearInterval(this.autoUpdaterIntervalId);

                this.autoUpdaterIntervalId = undefined;
            }
        },

        clearViewObserverTimeout () {
            if (this.viewMetricObserverTimeoutId) {
                clearTimeout(this.viewMetricObserverTimeoutId);

                this.viewMetricObserverTimeoutId = undefined;
            }
        },

        deconstructMetricsCollector () {
            this.clearUpdaterInterval();
            this.clearViewObserverTimeout();

            this.autoUpdaterObserver?.disconnect();
            this.impressionMetricObserver?.disconnect();
            this.viewMetricObserver?.disconnect();
        },
    },
    mounted () {
        if (this.collectMetrics) {
            this.constructMetricsCollector();
        }
    },
    unmounted () {
        if (this.collectMetrics) {
            this.deconstructMetricsCollector();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-poll {
    user-select: none;

    background-color: transparent;
}

.creator-section {
    margin: 0 21px;
}

.creator-avatar {
    cursor: pointer;

    width: 54px;
    height: 54px;
}

.creator-header-section {
    flex: 1;

    margin-left: 8px;
}

.creator-name {
    cursor: pointer;

    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02rem;
    color: rgb(252, 253, 252);
}

.badge-icon {
    width: 18px;
    margin-left: 2px;

    fill: $brand-color;
}

.creator-followers {
    cursor: pointer;

    margin: 2px 0;

    font-size: 12px;
    letter-spacing: 0.02rem;
    color: rgb(252, 253, 252);
}

.poll-creation-date {
    cursor: default;

    font-size: 12px;
    letter-spacing: 0.02rem;
    color: rgb(252, 253, 252);
}

.main-section {
    margin: 18px 21px 0 21px;
}

.question-section {
    margin: 0;
    padding: 0;
}
.question {
    cursor: pointer;

    font-size: 21px;
    font-weight: 700;
    letter-spacing: 0.008rem;
    color: rgb(255, 255, 255);
}

.description-section {
    margin: 2px 0 0 0;
    padding: 0;
}
.description {
    cursor: pointer;

    margin: 0;
    padding: 0;

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.005rem;
    color: rgb(255, 255, 255);
}

.privacy-section {
    margin: 10px 0 20px 0;
    padding: 0;
}
.privacy {
    cursor: default;

    font-size: 11px;
    font-weight: 500;
    color: rgb(255, 255, 255);
}
.privacy-link {
    cursor: pointer;

    font-weight: 600;
    color: $brand-color;
}

.options-section {
    margin: 20px 0 0 0;
}
.option-section {
    position: relative;

    & + & {
        margin-top: 10px;
    }

    .votes-percentage {
        pointer-events: none;

        position: absolute;
        right: 20px;
        top: 50%;

        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        color: $base-text-color;

        transform: translateY(-50%);
        filter: invert(1);
        mix-blend-mode: difference;

        z-index: 1;
    }
}
.option {
    --background-width: 100%;

    cursor: pointer;
    position: relative;

    will-change: color;
    transition: color 160ms;

    width: 100%;

    padding: 13px 20px;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.02rem;

    z-index: 1;

    &::before {
        content: "";

        will-change: width, background-color, border-color;
        transition: width 160ms, background-color 160ms, border-color 160ms;

        position: absolute;
        left: 0;
        top: 0;

        width: var(--background-width);
        height: 100%;

        background-color: rgb(255, 255, 255);
        border-radius: 8px;

        z-index: -1;
    }

    &-text {
        max-width: calc(100% - 60px);

        color: $base-text-color;

        filter: invert(1);
        mix-blend-mode: difference;
    }

    &-tick {
        margin: 0 0 0 4px;

        &__icon {
            position: absolute;
            top: 50%;
            left: -10px;

            width: 18px;

            transform: translateY(-50%) scaleX(-1);
            filter: drop-shadow(0 0 2px rgba(33, 32, 31, 0.07));
        }
    }
}
.simple-poll--closed {
    .option {
        cursor: default;
    }
}
.simple-poll--visible-votes {
    .option {
        &::before {
            background-color: rgb(29, 29, 29);
        }

        &--voted {
            cursor: default;

            &::before {
                background-image:
                    radial-gradient(circle, hsla(210, 100%, 88%, 1) 0%, hsl(0, 0%, 97%) 100%);
            }
        }
    }
}

.action-section {
    cursor: default;

    border-top: 1px solid rgb(33, 33, 33);
    padding: 20px 0;
}

.metrics-section {
    cursor: default;

    border-top: 1px solid rgb(33, 33, 33);
    padding-top: 20px;
}
.metric {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.04rem;
    color: $base-text-color;

    filter: invert(1);
    mix-blend-mode: difference;
}

.bottom-section {
    margin-top: 11px;

    .secondary-action-button {
        cursor: pointer;

        &__icon {
            width: 33px;

            color: rgb(253, 253, 253);
        }

        &__label {
            margin: 0 0 0 4px;

            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0.04rem;

            color: rgb(253, 253, 253);
        }
    }
    .secondary-action-button + .secondary-action-button {
        margin-left: 9px;
    }
}

.bottom-section {
    position: relative;

    height: 42px;
}

.categories-section {
    position: absolute;
    right: 0;
    top: 0;

    width: auto;
    height: 100%;
}
.category {
    cursor: pointer;
    position: absolute;
    top: 0;

    border-radius: 50%;
    box-shadow: rgba(33, 32, 31, 0.4) 0 0 20px 0;

    @for $i from 1 to 10 {
        &:nth-child(#{$i}) {
            right: #{($i - 1) * 20}px;
        }
    }
}
</style>
