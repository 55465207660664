<template>
    <!-- Represents a squared poll preview -->
    <div class="flex flex--100 simple-poll-square-preview">
        <div class="flex flex--100 flex--y-align-center main-section">
            <!-- <creator> -->
            <div class="flex flex--100 flex--y-align-center creator-section">
                <UserAvatar class="creator-avatar" :user="createdBy"/>
                <div class="flex creator-header">
                    <div class="flex flex--100">
                        <span class="flex flex--y-align-center creator-name-section">
                            <span class="creator-name">
                                {{ visibleCreatorName }}
                            </span>
                            <VerifiedBadgeIcon class="badge-icon"/>
                        </span>
                    </div>
                    <div class="flex flex--100">
                        <span class="flex poll-creation-date">
                            {{ visibleCreatedAt }} ago
                        </span>
                    </div>
                </div>
            </div>
            <!-- </creator> -->
            <!-- <question> -->
            <div class="flex flex--100 flex--x-align-center flex--y-align-center question-section">
                <span class="question">
                    {{ visibleQuestion }}
                </span>
            </div>
            <!-- </question> -->
            <!-- <metrics> -->
            <div class="flex flex--100 metrics-section">
                <div class="flex flex--50">
                    <span class="metric">{{ visibleTotalVotesCounter }}</span>
                </div>
                <div class="flex flex--50 flex--x-align-end">
                    <span class="metric">{{ visibleRemainingTime }}</span>
                </div>
            </div>
            <!-- </metrics> -->
        </div>
    </div>
</template>

<script>
import { pollsStore, } from "@/main";
import UserAvatar from "@/components/UserAvatar.vue";
import VerifiedBadgeIcon from "@/components/icons/VerifiedBadgeIcon.vue";
import { timeToCompactString, } from "@/utilities/Date";
import { numberToCompactString, } from "@/utilities/Utilities";

const QUESTION_MAX_LENGTH = 80;

export default {
    name: "SimplePollSquarePreview",
    components: {
        VerifiedBadgeIcon,
        UserAvatar,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        poll () {
            return pollsStore.get(this.id);
        },

        createdBy () {
            return this.poll.createdBy;
        },

        visibleCreatorName () {
            return this.createdBy.visibleName;
        },

        createdAt () {
            return new Date(this.poll.createdAt);
        },

        visibleCreatedAt () {
            return timeToCompactString(Date.now() - this.createdAt.getTime());
        },

        question () {
            return this.poll.question;
        },

        visibleQuestion () {
            let question = this.question;

            if (question.length > QUESTION_MAX_LENGTH) {
                question = `${question.substring(0, QUESTION_MAX_LENGTH).trim()}...`;
            }

            return question;
        },

        metrics () {
            return this.poll.metrics;
        },

        // Votes counters for each option
        votesCounters () {
            return this.metrics.votesCounters;
        },

        // Total votes counter
        totalVotesCounter () {
            let totalVotesCounter = 0;

            for (const optionVotesCounter of this.votesCounters) {
                totalVotesCounter += optionVotesCounter;
            }

            return totalVotesCounter;
        },

        visibleTotalVotesCounter () {
            return `${numberToCompactString(this.totalVotesCounter)} votes`;
        },

        expireAt () {
            return new Date(this.poll.expireAt);
        },

        remainingTimeMs () {
            return this.expireAt.getTime() - Date.now();
        },

        visibleRemainingTime () {
            const ms = this.remainingTimeMs;

            if (ms <= 0) {
                return "Final results";
            }

            return `${timeToCompactString(ms)} left`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/utilities.scss";

.simple-poll-square-preview {
    cursor: pointer;
    user-select: none;

    width: 50vw;
    max-width: 300px;
    height: 50vw;
    max-height: 300px;
    padding: 17px 9px;

    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba($brand-color, 0.1) 100%);

    border-bottom: $base-border;
    border-right: $base-border;

    &:nth-child(even) {
        background-image: linear-gradient(to left, rgb(255, 255, 255) 0%, rgba($brand-color, 0.1) 100%);
        border-right: none;
    }
}

.main-section {
    margin: 0;
    padding: 0;

    height: 100%;
}

.creator-avatar {
    cursor: pointer;

    width: 33px;
    height: 33px;
}

.creator-section {
    margin-bottom: 7px;
    padding: 0 9px;
}

.creator-header {
    overflow: hidden;

    flex: 1;

    margin-left: 5px;
}

.creator-name-section {
    @extend .text-no-wrap;
}

.creator-name {
    width: calc(100% - 20px); // 20px = badge icon width + margin left

    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.018rem;
    color: rgb(22, 22, 22);

    @extend .text-no-wrap;
}

.badge-icon {
    width: 18px;
    margin-left: 2px;

    fill: $brand-color;
}

.poll-creation-date {
    margin: 0;
    padding: 0;

    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.02rem;
    color: rgba(93, 93, 93, 0.9);
}

.question-section {
    height: 100px;
    margin: 11px 0;
}

.question {
    padding: 11px 7px;

    background-image: linear-gradient(-45deg, rgb(255, 255, 255) 0%, rgba($brand-color, 0.2) 100%);
    //border: 1px solid rgba(0, 0, 0, 0.01);
    border-radius: 12px;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.008rem;
    //font-style: italic;
    text-align: center;
    color: rgb(33, 33, 33);
}

.metrics-section {
    margin-top: 7px;
    padding: 0 9px;
}

.metric {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.02rem;
    color: rgba(93, 93, 93, 0.9);
}
</style>
