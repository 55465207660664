<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Playground</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>

        </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, } from "@ionic/vue";
import StripeSubscriptionForm from "@/components/billings/StripeSubscriptionForm.vue";

export default {
    name: "PlaygroundView",
    components: {
        StripeSubscriptionForm,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
    },
    data () {
        return {
            providers: [],
            meeting: undefined,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

ion-content {
    --ion-background-color: $grey-background;
}
</style>
