import { appleProducts, googleProducts } from "@/utilities/PricingPlans";
import { Capacitor, } from "@capacitor/core";
import { isPlatform } from "@ionic/vue";
import { LOG_LEVEL, Purchases, PURCHASES_ERROR_CODE, PurchasesStoreProduct, } from "@revenuecat/purchases-capacitor";

let isConfigured = false;

export async function getProducts () {
    let productIdentifiers = [] as string[];

    if (isPlatform("ios")) {
        productIdentifiers = appleProducts();
    }

    if (isPlatform("android")) {
        productIdentifiers = googleProducts();
    }

    const { products, } = await Purchases.getProducts({
        productIdentifiers,
    });

    const productsMap = {} as Record<string, PurchasesStoreProduct>;

    products.forEach((product) => {
        productsMap[product.identifier] = product;
    });

    return productsMap;
}

export async function purchaseProduct (product: PurchasesStoreProduct) {
    let transaction;

    try {
        transaction = await Purchases.purchaseStoreProduct({
            product,
        });
    }
    catch (error: any) {
        if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
            alert(error.message);
        }
        else {
            alert(error.message);
        }

        return undefined;
    }

    alert("Success!");

    return transaction;
}

export async function logInRevenueCatUser (userId: string) {
    return Purchases.logIn({
        appUserID: userId,
    });
}

export async function configureRevenueCat (userId: string) {
    if (isConfigured) {
        return;
    }

    let apiKey;

    if (Capacitor.getPlatform() === "ios") {
        apiKey = process.env.VUE_APP_REVENUE_CAT_IOS_API_KEY;
    }
    else if (Capacitor.getPlatform() === "android") {
        apiKey = process.env.VUE_APP_REVENUE_CAT_ANDROID_API_KEY;
    }
    else {
        return;
    }

    await Purchases.setLogLevel({
        level: LOG_LEVEL.DEBUG,
    });
    await Purchases.configure({
        apiKey,
        appUserID: userId,
    });

    isConfigured = true;
}
