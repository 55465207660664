import { countries, } from "@/utilities/Countries";
import { Device } from "@capacitor/device";
import { alertController, } from "@ionic/vue";
import confetti from "canvas-confetti";

export function numberToCompactString (value: number, precision = 0): string {
    let representation = value.toFixed();

    const billions = value / 1_000_000_000;
    const millions = value / 1_000_000;
    const thousands = value / 1_000;

    if (billions >= 1) {
        representation = `${billions.toFixed(precision)}b`;
    }
    else if (millions >= 1) {
        representation = `${millions.toFixed(precision)}m`;
    }
    else if (thousands >= 1) {
        representation = `${thousands.toFixed(precision)}k`;
    }

    return representation
        .replace(".0", "");
}

export async function presentMessageModal (message: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        const alert = await alertController.create({
            // header: "",
            // subHeader: "",
            message,
            buttons: [
                {
                    text: "OK",
                    handler: () => {
                        return resolve(true);
                    },
                },
            ],
        });

        await alert.present();
    });
}

export async function presentConfirmationModal (message: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        const alert = await alertController.create({
            message,
            buttons: [
                {
                    text: "Dismiss",
                    role: "dismiss",
                    handler: () => {
                        return resolve(false);
                    },
                },
                {
                    text: "Confirm",
                    role: "confirm",
                    handler: () => {
                        return resolve(true);
                    },
                },
            ],
        });

        await alert.present();
    });
}

export const GenderType = Object.freeze({
    MAN: 0,
    WOMAN: 1,
    NON_BINARY: 2,
    OTHER: 3,
});

export const AgeRangeType = Object.freeze({
    LESS_THAN_16: 0,
    RANGE_16_TO_24: 1,
    RANGE_25_TO_34: 2,
    RANGE_35_TO_44: 3,
    RANGE_45_TO_54: 4,
    RANGE_55_TO_64: 5,
    RANGE_65_TO_99: 6,
    MORE_THAN_99: 7,
});

export const IncomeRangeType = Object.freeze({
    PREFER_NOT_TO_SAY: 0,
    LESS_THAN_20000: 1,
    RANGE_20000_TO_30000: 2,
    RANGE_30001_TO_40000: 3,
    RANGE_40001_TO_50000: 4,
    RANGE_50001_TO_60000: 5,
    RANGE_60001_TO_70000: 6,
    RANGE_70001_TO_80000: 7,
    RANGE_80001_TO_90000: 8,
    RANGE_90001_TO_100000: 9,
    MORE_THAN_100000: 10
});

export const EducationType = Object.freeze({
    PREFER_NOT_TO_SAY: 0,
    HIGH_SCHOOL_DIPLOMA_OR_LOWER: 1,
    HIGH_SCHOOL_DIPLOMA_OR_EQUIVALENT: 2,
    VOCATIONAL_TRAINING: 3,
    BACHELOR_DEGREE: 4,
    MASTER_DEGREE: 5,
    DOCTORATE_OR_PHILOSOPHIAE_DOCTOR: 6,
});

export const ReligionType = Object.freeze({
    PREFER_NOT_TO_SAY: 0,
    CHRISTIAN_PROTESTANT: 1,
    CHRISTIAN_CATHOLIC: 2,
    CHRISTIAN_ORTHODOX: 3,
    OTHER_CHRISTIAN_DENOMINATION: 4,
    JUDAISM: 5,
    HINDUISM: 6,
    BUDDHISM: 7,
    SIKHISM: 8,
    OTHER: 9,
    ATHEISM: 10,
    AGNOSTIC: 11,
});

export const IndustryType = Object.freeze({
    PREFER_NOT_TO_SAY: 0,
    AGRICULTURE: 1,
    AUTOMOTIVE: 2,
    BANKING_AND_FINANCIAL_SERVICES: 3,
    CONSTRUCTION: 4,
    EDUCATION: 5,
    ENERGY_AND_UTILITIES: 6,
    ENTERTAINMENT_AND_MEDIA: 7,
    FOOD_AND_BEVERAGE: 8,
    HEALTHCARE: 9,
    HOSPITALITY_AND_TOURISM: 10,
    INFORMATION_TECHNOLOGY: 11,
    MANUFACTURING: 12,
    PROFESSIONAL_SERVICES: 13,
    REAL_ESTATE: 14,
    RETAIL: 15,
    TELECOMMUNICATIONS: 16,
    TRANSPORTATION_AND_LOGISTICS: 17,
    WHOLESALE_AND_DISTRIBUTION: 18,
    OTHER: 19,
});

export const FeedType = Object.freeze({
    FOR_YOU: 0,
    FOLLOWING: 1,
    RESULTS: 2,
});

export const PollActionType = Object.freeze({
    SHOP_NOW: 0,
    DONATE_NOW: 1,
    SIGN_PETITION: 2,
});

export const ActivityType = Object.freeze({
    POLLS: 0,
    COMMENTS: 1,
    VOTES: 2,
});

export const PollStatusType = Object.freeze({
    PENDING: 0,
    OPEN: 1,
    CLOSED: 2,
});

export const CreatorType = Object.freeze({
    ORGANIZATION: 0,
    PUBLIC_FIGURE: 1,
});

export const CreatorOrganizationSizeType = Object.freeze({
    RANGE_0_TO_1: 0,
    RANGE_2_TO_10: 1,
    RANGE_11_TO_50: 2,
    RANGE_51_TO_200: 3,
    RANGE_201_TO_500: 4,
    RANGE_501_TO_1000: 5,
    RANGE_1001_TO_5000: 6,
    RANGE_5001_TO_10000: 7,
    MORE_THAN_10000: 8,
});

export function getSystemCountry () {
    const systemCountryIso2 = window.navigator.userAgent.split("-")[1] ?? "GB";

    return countries
        .find((country) => country.iso2.toUpperCase() === systemCountryIso2);
}

export const systemCountry = getSystemCountry();

export const topEmojis = [
    "😂", // Face with Tears of Joy
    "❤️", // Red Heart
    "😭", // Loudly Crying Face
    "🤣", // Rolling on the Floor Laughing
    "🥺", // Pleading Face
    "✨", // Sparkles
    "🙏", // Folded Hands
    "😍", // Smiling Face with Heart-Eyes
    "🥰", // Smiling Face with Hearts
    "😊", // Smiling Face with Smiling Eyes
    "🔥", // Fire
    "😘", // Face Blowing a Kiss
    "👍", // Thumbs Up
    "🤗", // Hugging Face
    "😅", // Smiling Face with Sweat
    "🎉", // Party Popper
    "😎", // Smiling Face with Sunglasses
    "💕", // Two Hearts
    "😋", // Face Savoring Food
    "😏", // Smirking Face
];

export async function getDeviceLanguageIso () {
    const { value, } = await Device.getLanguageCode();

    return value.split("-")[0];
}

const onlyEmojisRegex =
    /^(?:\p{Emoji_Presentation}|\p{Emoji}\uFE0F)+$/u;

export function containsOnlyEmojis (text: string) {
    return onlyEmojisRegex.test(text);
}

export function countEmojis (text: string) {
    const emojiRegex = /\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu;
    const matches = text.match(emojiRegex);

    return matches ? matches.length : 0;
}

export const CreatorSubscriptionType = Object.freeze({
    LEVEL_1: 0,
    LEVEL_2: 1,
    LEVEL_3: 2,
});

export const APP_STORE_URI =
    process.env.VUE_APP_APP_STORE_URI;
export const PLAY_STORE_URI =
    process.env.VUE_APP_PLAY_STORE_URI;

export const isProduction =
    process.env.NODE_ENV === "production";

export function drawFireworks (durationMs: number): void {
    const animationEnd = Date.now() + durationMs;
    const defaults = {
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        zIndex: 0,
    };

    function randomInRange(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    const intervalId = setInterval(function() {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
            return clearInterval(intervalId);
        }

        const particleCount = 50 * (timeLeft / durationMs);

        confetti({
            ...defaults,
            particleCount,
            origin: {
                x: randomInRange(0.1, 0.3),
                y: Math.random() - 0.2,
            }
        });
        confetti({
            ...defaults,
            particleCount,
            origin: {
                x: randomInRange(0.7, 0.9),
                y: Math.random() - 0.2
            }
        });
    }, 250);
}

export function usePointer () {
    const pointer = window.document.createElement("div");

    pointer.style.transition = "opacity 90ms";
    pointer.style.position = "absolute";
    pointer.style.left = "0";
    pointer.style.top = "0";

    pointer.style.pointerEvents = "none";

    pointer.style.width = "32px";
    pointer.style.height = "32px";

    pointer.style.backgroundColor = "rgba(133, 133, 133, 0.4)";
    pointer.style.borderRadius = "50%";

    pointer.style.zIndex = "161803";
    pointer.style.opacity = "0";

    window.addEventListener("touchstart", (e) => {
        pointer.style.opacity = "1";
        pointer.style.left = `${e.touches[0].clientX - 16}px`;
        pointer.style.top = `${e.touches[0].clientY - 16}px`;
    });

    window.addEventListener("touchmove", (e) => {
        pointer.style.left = `${e.touches[0].clientX - 16}px`;
        pointer.style.top = `${e.touches[0].clientY - 16}px`;
    });

    window.addEventListener("touchend", (e) => {
        pointer.style.opacity = "0";
        pointer.style.left = `${e.touches[0].clientX - 16}px`;
        pointer.style.top = `${e.touches[0].clientY - 16}px`;
    });

    window.document.body.appendChild(pointer);
}
