<template>
    <IonPage>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <div class="flex flex--100 simple-poll-statistics" :class="pollStatisticsModifiers" v-if="!isLoading">
            <div class="flex flex--100 header-section">
                <!-- <question> -->
                <div class="flex flex--100 question-section">
                    <span class="question">{{ question }}</span>
                </div>
                <!-- </question> -->
                <!-- <privacy> -->
                <div class="flex flex--100 privacy-section">
                    <div class="privacy">
                        Votes are anonymous.
                        <span class="privacy-link" @click="toPollFaqView">Learn more</span>.
                    </div>
                </div>
                <!-- </privacy> -->
            </div>
            <div class="flex flex--100 main-section">
                <div class="flex flex--100 options-section">
                    <div
                        class="flex flex--100 option-section"
                        v-for="(option, i) in options"
                        :key="i"
                    >
                        <div
                            class="flex option"
                            :class="getOptionModifiers(i)"
                            :style="getOptionStyle(i)"
                            @click="selectOption(i)"
                        >
                            {{ getVisibleOption(option) }}
                        </div>
                        <div class="votes-percentage" v-if="votesAreVisible">
                            {{ numberToCompactString(votesCounters[i]) }} · {{ getOptionVotesPercentage(i) }}%
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 metrics-section">
                    <div class="flex flex--50">
                        {{ visibleTotalVotesCounter }} · {{ visibleRemainingTime }}
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        {{ visibleImpressionsCounter }}
                    </div>
                </div>
            </div>
            <div class="flex flex--100 granular-section" v-if="selectedOptionVotes > 0">
                <!-- <gender> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            By Gender
                        </h2>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byGender)"
                        >
                            <Chart :data="votesByGenderChartData" type="doughnut"/>
                        </div>
                    </div>
                </div>
                <!-- </gender> -->
                <!-- <age-range> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            By Age Range
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byAgeRange"
                        >
                                Upgrade to Light
                            </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byAgeRange)"
                        >
                            <Chart :data="votesByAgeRangeChartData" type="doughnut"/>
                        </div>
                    </div>
                </div>
                <!-- </age-range> -->
                <!-- <country> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            By Country
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byCountry"
                        >
                                Upgrade to Standard
                            </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byCountry)"
                        >
                            <Chart :data="votesByCountryChartData" type="doughnut"/>
                        </div>
                    </div>
                </div>
                <!-- </country> -->
                <!-- <education> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            By Education
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byEducation"
                        >
                                Upgrade to Standard
                            </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byEducation)"
                        >
                            <Chart :data="votesByEducationChartData" type="doughnut"/>
                        </div>
                    </div>
                </div>
                <!-- </education> -->
                <!-- <income-range> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            By Income Range
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byIncomeRange"
                        >
                                Upgrade to Premium
                            </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byIncomeRange)"
                        >
                            <Chart :data="votesByIncomeRangeChartData" type="doughnut"/>
                        </div>
                    </div>
                </div>
                <!-- </income-range> -->
                <!-- <industry> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            By Industry
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byIndustry"
                        >
                                Upgrade to Premium
                            </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byIndustry)"
                        >
                            <Chart :data="votesByIndustryChartData" type="doughnut"/>
                        </div>
                    </div>
                </div>
                <!-- </industry> -->
                <!-- <religion> -->
                <div class="flex flex--100 metric-chart-section">
                    <div class="flex flex--100 flex--y-align-center">
                        <h2 class="flex granular-title">
                            By Religion
                        </h2>
                        <span
                            class="flex upgrade-button"
                            @click="toCreatorPlansView"
                            v-if="!analyticsVisibility.byReligion"
                        >
                                Upgrade to Premium
                            </span>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                        <div
                            class="flex chart-section"
                            :class="chartSectionModifiers(analyticsVisibility.byReligion)"
                        >
                            <Chart :data="votesByReligionChartData" type="doughnut"/>
                        </div>
                    </div>
                </div>
                <!-- </religion> -->
            </div>
            <div class="flex flex--100 flex--x-align-center flex--y-align-center no-votes-section" v-else>
                <h2 class="no-votes-section__title">No votes yet</h2>
            </div>
        </div>
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonButtons,
    IonBackButton,
    IonButton,
    IonFooter,
    IonTextarea,
    IonSegment,
    IonSegmentButton,
} from "@ionic/vue";
import {
    Pie,
    Chart,
} from "vue-chartjs";
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, Colors} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);
ChartJS.register(Colors);

ChartJS.defaults.plugins.legend.position = "right";
ChartJS.defaults.plugins.legend.align = "start";

import { userManager, } from "@/UserManager";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/UserStore";
import SimplePoll from "@/components/polls/SimplePoll.vue";
import CommentIcon from "@/components/icons/CommentIcon.vue";
import SimplePollComment from "@/components/polls/comments/SimplePollComment.vue";
import SimplePollComments from "@/components/polls/comments/SimplePollComments.vue";
import {pollsStore} from "@/main";
import {
    EducationType,
    GenderType,
    IncomeRangeType,
    numberToCompactString, ReligionType,
    AgeRangeType, IndustryType,
} from "@/utilities/Utilities";
import UserAvatar from "@/components/UserAvatar.vue";
import SimpleCategoryIcon from "@/components/categories/SimpleCategoryIcon.vue";
import VerifiedBadgeIcon from "@/components/icons/VerifiedBadgeIcon.vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import ShareIcon from "@/components/icons/ShareIcon.vue";
import {timeToCompactString} from "@/utilities/Date";
import { countries, shortenCountryName, } from "@/utilities/Countries";

const OPTION_MAX_LENGTH = 30;

export default {
    name: "PollStatisticsView",
    components: {
        ShareIcon, ApplicationIcon, VerifiedBadgeIcon, SimpleCategoryIcon, UserAvatar,
        SimplePollComments,
        SimplePollComment,
        CommentIcon,
        SimplePoll,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
        IonFooter,
        IonTextarea,
        IonSegment,
        IonSegmentButton,
        Pie,
        Chart,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            isLoading: false,
            selectedOptionIndex: 0,
        };
    },
    computed: {
        StatisticsAgeRangeType () {
            return AgeRangeType;
        },
        GenderType () {
            return GenderType;
        },
        ...mapStores(useUserStore),

        analyticsVisibility () {
            return this.userStore.entitlements.analyticsVisibility;
        },

        votesByCountryChartData () {
            const selectedOptionStatistics = this.statistics[this.selectedOptionIndex];
            const labels = [];
            const data = [];

            Object.keys(selectedOptionStatistics.byCountry).forEach((countryIso) => {
                const matchedCountry =
                    countries.find((country) => country.iso2.toUpperCase() === countryIso.toUpperCase());

                if (matchedCountry) {
                    labels.push(`${matchedCountry.emojiIcon} ${shortenCountryName(matchedCountry.name)}`);
                }
                else {
                    labels.push(countryIso);
                }
            });

            for (const value of Object.values(selectedOptionStatistics.byCountry)) {
                data.push(value);
            }

            return {
                labels,
                datasets: [
                    {
                        label: "Votes",
                        data,
                    },
                ],
            };
        },

        votesByGenderChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(GenderType)) {
                labels.push(this.$t(`genderType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byGender[value] ?? 0);
            }

            return {
                labels,
                datasets: [
                    {
                        label: "Votes",
                        data,
                    },
                ],
            };
        },

        votesByAgeRangeChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(AgeRangeType)) {
                labels.push(this.$t(`ageRangeType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byAgeRange[value] ?? 0);
            }

            return {
                labels,
                datasets: [
                    {
                        label: "Votes",
                        data,
                    },
                ],
            };
        },

        votesByIncomeRangeChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(IncomeRangeType)) {
                labels.push(this.$t(`incomeRangeType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byIncomeRange[value] ?? 0);
            }

            return {
                labels,
                datasets: [
                    {
                        label: "Votes",
                        data,
                    },
                ],
            };
        },

        votesByEducationChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(EducationType)) {
                labels.push(this.$t(`educationType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byEducation[value] ?? 0);
            }

            return {
                labels,
                datasets: [
                    {
                        label: "Votes",
                        data,
                    },
                ],
            };
        },

        votesByReligionChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(ReligionType)) {
                labels.push(this.$t(`religionType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byReligion[value] ?? 0);
            }

            return {
                labels,
                datasets: [
                    {
                        label: "Votes",
                        data,
                    },
                ],
            };
        },

        votesByIndustryChartData () {
            const labels = [];
            const data = [];

            for (const value of Object.values(IndustryType)) {
                labels.push(this.$t(`industryType.${value}`));
                data.push(this.statistics[this.selectedOptionIndex].byIndustry[value] ?? 0);
            }

            return {
                labels,
                datasets: [
                    {
                        label: "Votes",
                        data,
                    },
                ],
            };
        },

        loggedUser () {
            return this.userStore.user;
        },
        poll () {
            return pollsStore.get(this.id);
        },
        metrics () {
            return this.poll.metrics;
        },
        statistics () {
            return this.poll.statistics;
        },
        // Total votes counter
        totalVotesCounter () {
            let totalVotesCounter = 0;

            for (const optionVotesCounter of this.votesCounters) {
                totalVotesCounter += optionVotesCounter;
            }

            return totalVotesCounter;
        },
        // Votes counters for each option
        votesCounters () {
            return this.metrics.votesCounters;
        },

        visibleTotalVotesCounter () {
            return `${numberToCompactString(this.totalVotesCounter)} votes`;
        },
        pollStatisticsModifiers () {
            return {
                "simple-poll-statistics--closed": this.isClosed,
                "simple-poll-statistics--visible-votes": this.votesAreVisible,
            };
        },
        votesAreVisible () {
            return true;
        },

        question () {
            return this.poll.question;
        },

        options () {
            return this.poll.options;
        },
        createdAt () {
            return new Date(this.poll.createdAt);
        },
        expireAt () {
            return new Date(this.poll.expireAt);
        },
        impressionsCounter () {
            return this.metrics.impressionsCounter;
        },
        visibleImpressionsCounter () {
            return `${numberToCompactString(this.impressionsCounter)} views`;
        },
        remainingTimeMs () {
            return this.expireAt.getTime() - Date.now();
        },
        isClosed () {
            return Date.now() >= this.expireAt.getTime();
        },
        visibleCreatedAt () {
            return timeToCompactString(Date.now() - this.createdAt.getTime());
        },
        visibleRemainingTime () {
            const ms = this.remainingTimeMs;

            if (ms <= 0) {
                return "Final results";
            }

            return `${timeToCompactString(ms)} left`;
        },
        selectedOptionVotes () {
            return this.votesCounters[this.selectedOptionIndex];
        },
    },
    methods: {
        numberToCompactString,

        toCreatorPlansView () {
            this.$router.push("/creator/plans");
        },

        chartSectionModifiers (isVisible) {
            return {
                "chart-section--blurred": !isVisible,
            };
        },

        async fetch () {
            this.isLoading = true;

            pollsStore.update(await userManager.getPoll(this.id));

            this.isLoading = false;
        },

        getVisibleOption (option) {
            if (option.length <= OPTION_MAX_LENGTH) {
                return option;
            }

            return `${option.substring(0, OPTION_MAX_LENGTH)}...`;
        },

        getOptionVotesPercentage (optionIndex) {
            const optionVotes = this.votesCounters[optionIndex];

            if (optionVotes === 0) {
                return 0;
            }

            return Number(Math.round(optionVotes / this.totalVotesCounter * 100).toFixed(0));
        },
        getOptionModifiers (optionIndex) {
            return {
                "option--selected": this.selectedOptionIndex === optionIndex,
            };
        },
        getOptionStyle (optionIndex) {
            const style = {};

            if (this.votesAreVisible) {
                const votedPercentage = Math.max(this.getOptionVotesPercentage(optionIndex), 1);

                style["--background-width"] = `${votedPercentage}%`;
            }

            return style;
        },
        toPollFaqView () {
            this.$router.push("/poll/faq");
        },

        selectOption (i) {
            this.selectedOptionIndex = i;
        },

        getVotesPercentage (votes) {
            if (votes === 0) {
                return 0;
            }

            return Math.round(votes / this.selectedOptionVotes * 100);
        },
    },
    ionViewWillEnter () {
        this.fetch();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/paywall.scss";

.simple-poll-statistics {
    flex-direction: column;

    min-height: 100%;

    background-color: rgb(255, 255, 255);
}

.poll-creation-date {
    font-size: 12px;
    letter-spacing: 0.02rem;
    color: rgb(122, 122, 122);
}

.header-section {
    margin: 0;
    padding: 20px 20px 0 20px;

    background-color: rgb(255, 255, 255);
}

.main-section {
    position: sticky;
    top: 0;
    left: 0;

    margin: 0;
    padding: 0 20px 20px 20px;

    background-color: rgb(255, 255, 255);
    border-bottom: $base-border;

    z-index: 10;
}

.granular-section {
    margin: 0;
    padding: 21px;

    background-color: rgb(243, 243, 243);
}

.metric-chart-section {
    margin: 0;
    padding: 0;

    & + & {
        margin-bottom: 21px;
        padding-top: 21px;

        border-top: $base-border;
    }
}

.question-section {

}
.question {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.008rem;
    color: rgb(33, 33, 33);
}

.privacy-section {
    margin: 4px 0 0 0;
}
.privacy {
    font-size: 11px;
    font-weight: 400;
    color: rgb(100, 100, 100);
}

.options-section {
    margin: 20px 0;
}
.option-section {
    position: relative;

    & + & {
        margin-top: 10px;
    }

    .votes-percentage {
        pointer-events: none;

        position: absolute;
        right: 20px;
        top: 50%;

        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.02rem;
        color: rgb(66, 66, 66);

        transform: translateY(-50%);

        z-index: 1;
    }
}
.option {
    $border-width: 2px;

    --background-width: 100%;

    cursor: pointer;
    position: relative;

    will-change: color;
    transition: color 160ms;

    width: 100%;

    padding: 9px 20px;
    border: $border-width solid transparent;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.02rem;
    color: $strong-brand-color;

    z-index: 1;

    &::before {
        content: "";

        will-change: width, background-color, border-color;
        transition: width 160ms, background-color 160ms, border-color 160ms;

        position: absolute;
        left: -$border-width;
        top: -$border-width;

        width: var(--background-width);
        height: 100%;

        background-color: transparent;
        border: $border-width solid $strong-brand-color;
        border-radius: 8px;

        z-index: -1;
    }
}
.simple-poll-statistics--closed {
    .main-section {
        background-color: rgb(253, 253, 253);
    }
}
.simple-poll-statistics--visible-votes {
    .option {
        color: rgb(66, 66, 66);

        &::before {
            background-color: rgb(227, 229, 224);
            border-color: rgb(227, 229, 224);
        }

        &--selected {
            color: $strong-brand-color;

            &::before {
                background-color: rgb(207, 225, 255);
                border-color: rgb(207, 225, 255);
            }
        }
    }
}

.privacy-link {
    cursor: pointer;

    font-weight: 600;
    color: $strong-brand-color;
}

.metrics-section {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.04rem;

    color: rgb(60, 60, 60);
}

.granular-title {
    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.008rem;
    color: rgba(33, 33, 33, 0.9);
}

.upgrade-button {
    @extend .paywall-button;

    margin-left: 5px;
}

.chart-section {
    width: 90%;
    max-width: 364px;

    z-index: 1;

    &--blurred {
        @extend .paywall-blur;
    }
}

.no-votes-section {
    cursor: default;
    user-select: none;

    flex-grow: 1;

    &__title {
        margin: 21px;

        font-size: 23px;
        font-weight: 600;
        letter-spacing: 0.01rem;
        color: rgb(0, 0, 0);
        text-align: center;
    }
}
</style>
