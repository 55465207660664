<template>
    <div class="flex flex--100 simple-poll-action-button">
        <div class="action-button" @click="takeAction">
            <span class="action-button__text">{{ actionText }}</span>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import { mapStores, } from "pinia";
import { usePollsStore, } from "@/stores/PollsStore";
import { Browser, } from "@capacitor/browser";
import { PollActionType, } from "@/utilities/Utilities";
import { userManager } from "@/UserManager";

export default defineComponent({
    name: "SimplePollActionButton",
    props: {
        pollId: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapStores(usePollsStore),

        poll () {
            return this.pollsStore.get(this.pollId);
        },

        actionType () {
            return this.poll.actionType;
        },

        actionUri () {
            return this.poll.actionUri;
        },

        actionText () {
            switch (this.actionType) {
                case PollActionType.SHOP_NOW: {
                    return "Shop Now";
                }
                case PollActionType.DONATE_NOW: {
                    return "Donate Now";
                }
                case PollActionType.SIGN_PETITION: {
                    return "Sign Petition";
                }
            }

            throw new Error("UnknownActionTypeError");
        },
    },
    methods: {
        async hitAction () {
            await userManager.hitPollAction(this.pollId);
        },

        async takeAction () {
            try {
                this.hitAction().then().catch((error) => {
                    console.error(error);
                });
            }
            catch {
                // Silence is golden
            }

            await Browser.open({
                url: this.actionUri,
            });
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-poll-action-button {

}

.action-button {
    cursor: pointer;
    position: relative;

    width: 100%;
    padding: 13px 20px;

    background-color: rgb(244, 244, 244);
    background-image:
        linear-gradient(to bottom, hsla(210, 100%, 88%, 1) 0%, hsl(0, 0%, 97%) 100%);
    border-radius: 999px;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.02rem;
    text-align: center;

    &__text {
        color: $base-text-color;

        filter: invert(1);
        mix-blend-mode: difference;
    }
}
</style>
